import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, finalize, Observable } from 'rxjs';
import { BaseService } from '..';
import {
	FacilityRoomApiModel,
	FinalizeEncounterApiModel,
	MoveEncounterRoomApiModel,
	NarrativeApiModel,
	SaveEncounterNotesApiModel,
	SaveNoteApiModel
} from '../../generated-models';
import { AppLoaderService, NotificationService } from '../../layout';

@Injectable({
	providedIn: 'root'
})
export class EncounterService extends BaseService<void> {
	private encounterNarrativeSubject = new BehaviorSubject<NarrativeApiModel | null>(null);
	encounterNarrative$ = this.encounterNarrativeSubject.asObservable();

	questionnaireSubject: any;
	questionnaireLoadingSubject: any;

	constructor(
		_httpClient: HttpClient,
		_notificationService: NotificationService,
		private _appLoaderService: AppLoaderService
	) {
		super('encounter', _httpClient, _notificationService);
	}

	public moveRoom(encounterId: string, room: FacilityRoomApiModel): Observable<HttpResponse<void>> {
		const request: MoveEncounterRoomApiModel = {
			encounterId: encounterId,
			roomFhirId: room.fhirLocationId,
			roomFhirDisplay: room.label ?? ''
		};
		return this.post<MoveEncounterRoomApiModel, void>(`move-room`, 'Failed to move rooms.', request);
	}

	public saveEncounterNotes(
		encounterId: string,
		patientId: string,
		notes: SaveNoteApiModel[]
	): Observable<HttpResponse<void>> {
		const processId = this._appLoaderService.startLoadProcess();
		const model: SaveEncounterNotesApiModel = {
			encounterId: encounterId,
			patientId: patientId,
			notes: notes
		};
		return this.post<SaveEncounterNotesApiModel, void>(
			`save-notes`,
			'Failed to save encounter notes.',
			model
		).pipe(
			finalize(() => {
				this._appLoaderService.completeLoadProcess(processId);
			})
		);
	}

	public fetchEncounterNarrative(encounterId: string): Observable<HttpResponse<NarrativeApiModel>> {
		const processId = this._appLoaderService.startLoadProcess();
		return this.get<NarrativeApiModel>(`${encounterId}/narrative`, 'Error fetching narrative').pipe(
			finalize(() => {
				this._appLoaderService.completeLoadProcess(processId);
			})
		);
	}

	public fetchDocument(encounterId: string, patientId: string): Observable<HttpResponse<string>> {
		const processId = this._appLoaderService.startLoadProcess();
		return this.get<string>(`${encounterId}/patient/${patientId}/documents`, 'error').pipe(
			finalize(() => {
				this._appLoaderService.completeLoadProcess(processId);
			})
		);
	}

	public finalizeEncounter(encounterId: string) {
		const processId = this._appLoaderService.startLoadProcess();
		return this.post<FinalizeEncounterApiModel, unknown>('finalize', 'Could not finalize encounter', {
			encounterId: encounterId
		}).pipe(
			finalize(() => {
				this._appLoaderService.completeLoadProcess(processId);
			})
		);
	}
}
