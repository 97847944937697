/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum OrderRequestStatusApiEnum {
    Draft = 0,
    Ready = 1,
    InProgress = 2,
    OnHold = 3,
    Completed = 4,
    Canceled = 5,
}

export const OrderRequestStatusApiEnumDisplayMap : { [key in OrderRequestStatusApiEnum] : string } = {
    [OrderRequestStatusApiEnum.Draft]: "Draft",
    [OrderRequestStatusApiEnum.Ready]: "Ready",
    [OrderRequestStatusApiEnum.InProgress]: "InProgress",
    [OrderRequestStatusApiEnum.OnHold]: "OnHold",
    [OrderRequestStatusApiEnum.Completed]: "Completed",
    [OrderRequestStatusApiEnum.Canceled]: "Canceled",
}
