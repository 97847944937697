import { CommonModule } from '@angular/common';
import { Component, input, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { Subject } from 'rxjs';
import { GatedInputDirective } from '../../../directives/gated-input.directive';
import { BaseInputFormFieldComponent } from '../base-input-form-field';
import { ReadonlyDisplayInputComponent } from '../readonly-display-input/readonly-display-input.component';

@Component({
	standalone: true,
	selector: 'height-input-form-field',
	templateUrl: './height-input-form-field.component.html',
	styleUrl: './height-input-form-field.component.scss',
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		MatInputModule,
		ReadonlyDisplayInputComponent,
		GatedInputDirective
	]
})
export class HeightInputFormFieldComponent extends BaseInputFormFieldComponent implements OnInit, OnDestroy {
	private _destroy: Subject<void> = new Subject();

	public feetGate = (inputValue: string) => /^\d$/.test(inputValue);
	public inchGate = (inputValue: string) => {
		// the value '.' won't parse as a float, but we need to allow it while the user is typing
		if (inputValue == '.') {
			return true;
		}
		const numberValue = parseFloat(inputValue);
		if (isNaN(numberValue) || numberValue >= 12) {
			return false;
		}

		if (inputValue.includes('.')) {
			// these input formats should be allowed
			// 1.
			// 1.2
			// 0.2
			// .2
			const allowedNumbersAfterDecimalPoint = 1;
			const allewedNumberOfDecimalPoints = 1;
			const splits = inputValue.split('.');
			if (splits.length != allewedNumberOfDecimalPoints + 1) {
				return false;
			}
			const beforeDecimal = splits[0];
			const afterDecimal = splits[1];
			return (
				afterDecimal.length <= allowedNumbersAfterDecimalPoint &&
				beforeDecimal.length <= 2 &&
				beforeDecimal != '00'
			);
		}
		// We don't want to allow '00' or '00000' or '01'
		return inputValue.length == 1 || !inputValue.startsWith('0');
	};

	public feetCtrl = new FormControl<number | null>({ value: null, disabled: false });
	public inchCtrl = new FormControl<number | null>({ value: null, disabled: false });

	public readonly = input<boolean>(false);
	public maxDecimalPlaces = input<number | null | undefined>(3);

	// -- Object Life Cycle Functions
	constructor() {
		super('The value');
	}

	ngOnInit() {
		const footToCMConversion = 30.48;
		const inchToCMConversion = 2.54;
		const startVal = this.formCtrl.value;
		if (startVal != null && startVal != undefined) {
			const cm = parseFloat(startVal);
			const startFeet = Math.floor(cm / footToCMConversion);
			const cmLeftover = cm - footToCMConversion * startFeet;
			// Round the inch conversion to the tenths place
			const startInches = Math.round((cmLeftover * 10) / inchToCMConversion) / 10;
			this.feetCtrl.setValue(startFeet);
			this.inchCtrl.setValue(startInches);
		}

		this.feetCtrl.valueChanges.subscribe((feet: number | null) => {
			this.convertToCmAndSetForm(feet, this.inchCtrl.value);
		});

		this.inchCtrl.valueChanges.subscribe((inches: number | null) => {
			this.convertToCmAndSetForm(this.feetCtrl.value, inches);
		});
	}

	convertToCmAndSetForm(feet: number | string | null, inches: number | string | null): void {
		let feetNum = typeof feet == 'string' ? parseFloat(feet) : feet;
		let inchNum = typeof inches == 'string' ? parseFloat(inches) : inches;
		feetNum = feetNum == null || isNaN(feetNum) ? null : feetNum;
		inchNum = inchNum == null || isNaN(inchNum) ? null : inchNum;

		if (feetNum == null && inchNum == null) {
			this.formCtrl.setValue(null);
			return;
		}

		const totalInches = (feetNum ?? 0) * 12 + (inchNum ?? 0);
		const centimeters = totalInches * 2.54;

		const places = this.maxDecimalPlaces() ?? 3;
		const cmValue = parseFloat(centimeters.toFixed(places));
		this.formCtrl.setValue(cmValue);
	}

	ngOnDestroy(): void {
		this._destroy.complete();
	}
}
