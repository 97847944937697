<div style="display: flex; gap: 8px">
	@if (readonly()) {
		<readonly-display-input style="flex: 1" [label]="label" [value]="feetCtrl.value?.toString()" suffix="ft" />
		<readonly-display-input [value]="inchCtrl.value?.toString()" suffix="in" />
		<readonly-display-input [value]="formCtrl.value?.toString()" suffix="cm" />
	} @else {
		<div class="form-field-wrapper">
			<mat-form-field [ngClass]="{ short: short }">
				<mat-label style="white-space: nowrap">{{ label }}</mat-label>
				@if (focusInitial) {
					<input matInput gatedInput [gateFunction]="feetGate" [formControl]="feetCtrl" />
				} @else {
					<input matInput gatedInput [gateFunction]="feetGate" [formControl]="feetCtrl" />
				}
				<span class="suffix" matSuffix>ft</span>
			</mat-form-field>
		</div>
		<div class="form-field-wrapper">
			<mat-form-field [ngClass]="{ short: short }">
				<input matInput gatedInput [gateFunction]="inchGate" [formControl]="inchCtrl" />
				<span class="suffix" matSuffix>in</span>
			</mat-form-field>
		</div>
		<readonly-display-input [value]="formCtrl.value" suffix="cm" />
	}

	<ng-template ngFor let-customError [ngForOf]="customErrors" ngProjectAs="mat-error">
		@if (formCtrl && formCtrl.hasError(customError.type)) {
			<mat-error>{{ customError.message }}</mat-error>
		}
	</ng-template>
</div>
