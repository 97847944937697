import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { computed, Injectable, signal, WritableSignal } from '@angular/core';
import { finalize, tap } from 'rxjs';
import { AuthService } from '../../auth';
import {
	CareTeamApiModel,
	CareTeamParticipantComponentApiModel,
	ChiefComplaintRowApiModel,
	EncounterApiModel,
	EncounterLocationApiModel,
	EncounterStatusApiEnum,
	GenderApiEnum,
	NarrativeApiModel,
	PatientApiModel,
	ReadNotesApiModel
} from '../../generated-models';
import { AppLoaderService, NotificationService } from '../../layout';
import { PatientListService } from '../../patient-list/patient-list.service';
import { BaseService } from './base-service.service';

const male = GenderApiEnum.Male;
const female = GenderApiEnum.Female;

@Injectable({
	providedIn: 'root'
})
export class SiteWideDataService extends BaseService<void> {
	private patientTrackerDataIsLoaded = false;
	private patientTrackerDataPromise: Promise<EncounterApiModel[]> | null = null;

	private patientTrackerData = signal<EncounterApiModel[]>([]);
	private practitionerTrackerData = signal<CareTeamApiModel | null>(null);

	public selectedEncounter = signal<EncounterApiModel | null>(null);
	public selectedCareTeam = signal<CareTeamApiModel | null>(null);

	public selectedEncounterId = computed<string | null>(() => this.selectedEncounter()?.id ?? null);

	public selectedEncounterStatus = computed<EncounterStatusApiEnum | null>(
		() => this.selectedEncounter()?.statusEnum ?? null
	);

	public selectedEncounterIsActive = computed<boolean>(() => {
		const status = this.selectedEncounterStatus();
		return status == EncounterStatusApiEnum.InProgress || status == EncounterStatusApiEnum.Arrived;
	});

	public selectedPatient = computed<PatientApiModel | null>(() => this.selectedEncounter()?.patient ?? null);

	public selectedPatientId = computed<string | null>(() => this.selectedPatient()?.id ?? null);

	public selectedPatientGender = computed<GenderApiEnum.Male | GenderApiEnum.Female>(() =>
		this.selectedPatient()?.gender == female ? female : male
	);

	public selectedpatientIsFemale = computed<boolean>(() => this.selectedPatientGender() == female);

	public selectedpatientIsMale = computed<boolean>(() => this.selectedPatientGender() == male);

	public selectedEncounterNarrative = computed<NarrativeApiModel | null>(
		() => this.selectedEncounter()?.text ?? null
	);

	public primaryTenantChiefComplaint = computed<ChiefComplaintRowApiModel | null>(() => {
		const encounter = this.selectedEncounter();
		return encounter && encounter.chiefComplaints ? (encounter.chiefComplaints[0] ?? null) : null;
	});

	public primaryTenantChiefComplaintId = computed(() => this.primaryTenantChiefComplaint()?.sophosId ?? 0);

	public selectedPractitionerDisplay = computed<string | null>(() => {
		if (this.selectedEncounter() == null) {
			return null;
		} else {
			const selectedTeam =
				this.selectedEncounter()
					?.careTeams?.[0]?.practitioners?.map(teamMember => teamMember.member?.display)
					.join(', ') ?? null;
			return selectedTeam;
		}
	});

	public selectePatientDobString = computed<string | null>(() => {
		const dateString = this.selectedPatient()?.dateOfBirth;
		if (!dateString) {
			return null;
		}
		const datePipe = new DatePipe('en-US');
		return datePipe.transform(dateString, 'MM/dd/yyyy');
	});

	public selectedPatientAge = computed<number | null>(() =>
		this.getAgeFromDobString(this.selectePatientDobString())
	);

	public selectedPatientDisplayName = computed<string | null>(() => {
		const pat = this.selectedPatient();
		if (!pat) {
			return null;
		}
		let name = '';
		if (pat.firstName) {
			name += pat.firstName + ' ';
		}
		if (pat.lastName) {
			name += pat.lastName;
		}
		return name.trim();
	});

	selectedPractitioner: any;

	private getAgeFromDobString(dobStr: string | null | undefined): number | null {
		if (!dobStr) {
			return null;
		}
		const dob = dobStr ? new Date(dobStr) : new Date();
		const diffMs = Date.now() - dob.getTime();
		const ageDt = new Date(diffMs);
		const ageInYears = Math.abs(ageDt.getUTCFullYear() - 1970);
		return ageInYears;
	}

	public getPatientAge(patient: PatientApiModel): number | null {
		const dobStr = patient.dateOfBirth;
		return this.getAgeFromDobString(dobStr);
	}

	constructor(
		private readonly _patientListService: PatientListService,
		private readonly _appLoaderService: AppLoaderService,
		readonly _authService: AuthService,
		_httpClient: HttpClient,
		_notificationService: NotificationService
	) {
		super(null, _httpClient, _notificationService);
	}

	async setSelectedEncounterById(encounterId: string): Promise<WritableSignal<EncounterApiModel | null>> {
		if (this.selectedEncounter()?.id != encounterId) {
			const patientTrackerData = await this.loadPatientListInfoIfNeeded();

			const patientRow = patientTrackerData.find((e: EncounterApiModel) => e.id === encounterId);
			if (patientRow) {
				this.selectedEncounter.set(patientRow);
				return Promise.resolve(this.selectedEncounter);
			}
			throw new Error(`PatientTrackerEncounter row for "${encounterId}" was not found`);
		}
		return Promise.resolve(this.selectedEncounter);
	}

	async setSelectedCareTeam(currentUserId: string): Promise<WritableSignal<CareTeamApiModel | null>> {
		// Check if the current user is already part of the selected care team
		const selectedCareTeam = this.selectedCareTeam();
		if (
			selectedCareTeam?.practitioners?.some(
				(participant: CareTeamParticipantComponentApiModel) => participant.member?.reference === currentUserId
			)
		) {
			// Load practitioner info if the user is part of the care team
			const careTeamTrackerData = await this.loadPractitionerInfo();
		}

		// If the user is not part of the selected care team
		return Promise.resolve(this.selectedCareTeam);
	}

	private async loadPatientListInfoIfNeeded(): Promise<EncounterApiModel[]> {
		if (this.patientTrackerDataPromise !== null) {
			return this.patientTrackerDataPromise;
		}

		this.patientTrackerDataPromise = new Promise(resolve => {
			if (!this.patientTrackerDataIsLoaded) {
				const processId = this._appLoaderService.startLoadProcess();
				this._patientListService
					.getAllPatientsDetailed()
					.pipe(
						tap(response => {
							if (response.ok) {
								const serverData = response.body ?? [];
								if (serverData) {
									this.patientTrackerData.set(serverData);
								} else {
									console.error('Dashboard data is null or undefined.');
								}
							}
							this.patientTrackerDataIsLoaded = true;
							resolve(this.patientTrackerData());
						}),
						finalize(() => {
							this._appLoaderService.completeLoadProcess(processId);
						})
					)
					.subscribe();
			} else {
				resolve(this.patientTrackerData());
			}
		});

		return this.patientTrackerDataPromise;
	}

	private async loadPractitionerInfo(): Promise<CareTeamApiModel> {
		const selectedCareTeamTrackerDataPromise = new Promise<CareTeamApiModel>(resolve => {
			if (!this.patientTrackerDataIsLoaded) {
				const processId = this._appLoaderService.startLoadProcess();
				this._patientListService
					.getAllCareTeamDetailed()
					.pipe(
						tap(response => {
							if (response.ok) {
								const serverData = response.body ?? [];
								if (serverData) {
									this.practitionerTrackerData.set(serverData as CareTeamApiModel);
								} else {
									console.error('Dashboard data is null or undefined.');
								}
							}
							this.patientTrackerDataIsLoaded = true;
						}),
						finalize(() => {
							this._appLoaderService.completeLoadProcess(processId);
						})
					)
					.subscribe();
			}
		});

		return selectedCareTeamTrackerDataPromise;
	}

	selectedCareTeamTrackerDataPromise(): CareTeamApiModel | null {
		return this.practitionerTrackerData();
	}

	getCurrentDashboardSignal(): WritableSignal<EncounterApiModel[]> {
		this.loadPatientListInfoIfNeeded();
		return this.patientTrackerData;
	}

	public AddOrUpdateEncounter(encoutner: EncounterApiModel) {
		// Update loaded item
		this.patientTrackerData.update(old => {
			const existingEncounterIndex = old.findIndex((x: EncounterApiModel) => x.id === encoutner.id);

			const next = [...old];
			if (existingEncounterIndex > -1) {
				next[existingEncounterIndex] = encoutner;
			} else {
				next.push(encoutner);
			}

			// if we updated a patient via MRN or Id, we should update those changes too
			next.forEach(item => {
				const newId = encoutner.patient.id;
				const newMRN = encoutner.patient.medicalRecordNumber;
				if ((newId && item.patient.id == newId) || (newMRN && item.patient.medicalRecordNumber == newMRN)) {
					item.patient = encoutner.patient;
				}
			});
			return next;
		});
	}

	public EncounterStatusChanged(encounterId: string, status: EncounterStatusApiEnum) {
		// update the encounter status and removed the finished items
		this.patientTrackerData.update(old => {
			const existingEncounter = old.find((x: EncounterApiModel) => x.id === encounterId);
			if (!existingEncounter) {
				return old;
			}
			existingEncounter.statusEnum = status;
			return old.filter(encounter => encounter.statusEnum != EncounterStatusApiEnum.Finished);
		});
	}

	public EncounterOrdersUpdated(
		encounterId: string,
		activeAncillaryOrders: number,
		activeLaboratoryOrders: number,
		activePharmacyOrders: number,
		activeRadiologyOrders: number
	) {
		this.patientTrackerData.update(old => {
			const existingEncounter = old.find((x: EncounterApiModel) => x.id === encounterId);
			if (!existingEncounter) {
				return old;
			}
			existingEncounter.pendingAncillaryOrderCount = activeAncillaryOrders;
			existingEncounter.pendingLabOrderCount = activeLaboratoryOrders;
			existingEncounter.pendingPharmacyOrderCount = activePharmacyOrders;
			existingEncounter.pendingRadiologyOrderCount = activeRadiologyOrders;
			return [...old];
		});
	}

	public UpdateEncounterChiefComplaints(encounterId: string, chiefComplaints: ChiefComplaintRowApiModel[]) {
		// Update loaded item
		this.patientTrackerData.update(old => {
			const updatedEncounter = this.patientTrackerData().find((x: EncounterApiModel) => x.id === encounterId);

			// no change if there is no encounter
			if (!updatedEncounter) {
				return old;
			}
			const next = [...old];

			updatedEncounter.chiefComplaints = chiefComplaints;

			return next;
		});

		// Update the selected item
		if (this.selectedEncounterId() === encounterId) {
			this.selectedEncounter.update((old: EncounterApiModel | null) => {
				if (!old) {
					return old;
				}
				const next = { ...old };
				next.chiefComplaints = chiefComplaints;
				return next;
			});
		}
	}

	public UpdateEncounterNotes(encounterId: string, notes: ReadNotesApiModel[]) {
		// Update loaded item
		this.patientTrackerData.update(old => {
			const updatedEncounter = this.patientTrackerData().find((x: EncounterApiModel) => x.id === encounterId);

			// no change if there is no encounter
			if (!updatedEncounter) {
				return old;
			}
			const next = [...old];

			updatedEncounter.notes = notes;

			return next;
		});

		// Update the selected item
		if (this.selectedEncounterId() === encounterId) {
			this.selectedEncounter.update((old: EncounterApiModel | null) => {
				if (!old) {
					return old;
				}
				const next = { ...old };
				next.notes = notes;
				return next;
			});
		}
	}

	public UpdateEncounterRoom(encounterId: string, room: EncounterLocationApiModel) {
		this.patientTrackerData.update(old => {
			const updatedEncounter = old.find((x: EncounterApiModel) => x.id === encounterId);
			if (!updatedEncounter) {
				return old;
			}

			updatedEncounter.currentLocation = room;
			return [...old];
		});

		// Update selected item
		if (this.selectedEncounterId() === encounterId) {
			this.selectedEncounter.update((old: EncounterApiModel | null) => {
				if (!old) {
					return old;
				}
				const next = { ...old };
				next.currentLocation = room;
				return next;
			});
		}
	}

	public UpdateEncounterNarrative(encounterId: string, narrative: NarrativeApiModel) {
		// Update loaded item
		this.patientTrackerData.update(old => {
			const updatedEncounter = this.patientTrackerData().find((x: EncounterApiModel) => x.id === encounterId);

			// no change if there is no encounter
			if (!updatedEncounter) {
				return old;
			}
			const next = [...old];

			updatedEncounter.text = narrative;

			return next;
		});

		// Update the selected item
		if (this.selectedEncounterId() === encounterId) {
			this.selectedEncounter.update((old: EncounterApiModel | null) => {
				if (!old) {
					return old;
				}
				const next = { ...old };
				if (next.text) {
					//next.text.div += narrative.div;
				} else {
					next.text = narrative;
				}
				return next;
			});
		}
	}
}
