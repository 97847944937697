import { CommonModule } from '@angular/common';
import { Component, input } from '@angular/core';
import { MatCheckbox, MatCheckboxChange } from '@angular/material/checkbox';
import { BaseInputFormFieldComponent } from '../base-input-form-field/base-input-form-field.component';

@Component({
	selector: 'checkbox-input-form-field',
	standalone: true,
	imports: [CommonModule, MatCheckbox],
	templateUrl: './checkbox-input-form-field.component.html',
	styleUrl: './checkbox-input-form-field.component.scss'
})
export class CheckboxInputFormFieldComponent extends BaseInputFormFieldComponent {
	public readonly = input<boolean>(false);

	constructor() {
		super('A single checkbox');
	}

	onCheckboxChange($event: MatCheckboxChange) {
		this.formCtrl.setValue($event.checked);
	}
}
