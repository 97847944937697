/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum InsytsPageContextApiEnum {
    Assessment = 0,
    Progress = 1,
    Triage = 2,
    Exam = 3,
    History = 4,
    Orders = 5,
    MedicalDecisionMaking = 6,
    Discharge = 7,
    ChiefComplaint = 8,
    eRX = 9,
}

export const InsytsPageContextApiEnumDisplayMap : { [key in InsytsPageContextApiEnum] : string } = {
    [InsytsPageContextApiEnum.Assessment]: "Assessment",
    [InsytsPageContextApiEnum.Progress]: "Progress",
    [InsytsPageContextApiEnum.Triage]: "Triage",
    [InsytsPageContextApiEnum.Exam]: "Exam",
    [InsytsPageContextApiEnum.History]: "History",
    [InsytsPageContextApiEnum.Orders]: "Orders",
    [InsytsPageContextApiEnum.MedicalDecisionMaking]: "MedicalDecisionMaking",
    [InsytsPageContextApiEnum.Discharge]: "Discharge",
    [InsytsPageContextApiEnum.ChiefComplaint]: "ChiefComplaint",
    [InsytsPageContextApiEnum.eRX]: "eRX",
}
