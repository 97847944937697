@if (showFormField) {
	@if (readonly() || formCtrl.disabled) {
		<readonly-display-input
			[ngClass]="{ 'full-width': fullWidth }"
			[label]="label"
			[value]="getOptionLabel(formCtrl.value)" />
	} @else {
		<mat-form-field [ngClass]="{ 'full-width': fullWidth, short: short }">
			@if (label) {
				<mat-label>{{ label }}</mat-label>
			}

			@if (focusInitial) {
				<mat-select [formControl]="formCtrl" [multiple]="multiple" panelWidth="auto" cdkFocusInitial>
					@if (isIdLabelOptions) {
						@for (option of idLabelOptions; track option.id) {
							<mat-option [value]="option.id">{{ option.label }}</mat-option>
						}
					} @else if (isKeyLabelOptions) {
						@for (option of keyLabelOptions; track option.key) {
							<mat-option [value]="option.key">{{ option.label }}</mat-option>
						}
					}
				</mat-select>
			} @else {
				<mat-select [formControl]="formCtrl" [multiple]="multiple" panelWidth="auto">
					@if (isIdLabelOptions) {
						@for (option of idLabelOptions; track option.id) {
							<mat-option [value]="option.id">{{ option.label }}</mat-option>
						}
					} @else if (isKeyLabelOptions) {
						@for (option of keyLabelOptions; track option.key) {
							<mat-option [value]="option.key">{{ option.label }}</mat-option>
						}
					}
				</mat-select>
			}

			@if (!hideErrorText) {
				@if (formCtrl && formCtrl.hasError('required')) {
					<mat-error>A {{ errorLabelToLower }} selection is required.</mat-error>
				}

				<ng-template ngFor let-customError [ngForOf]="customErrors" ngProjectAs="mat-error">
					@if (formCtrl && formCtrl.hasError(customError.type)) {
						<mat-error>{{ customError.message }}</mat-error>
					}
				</ng-template>
			}
		</mat-form-field>
	}
}
