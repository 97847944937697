import { Directive, ElementRef, HostListener, input } from '@angular/core';

const defaultMaxDecimalPlaces = 3;

const createDecimalGate = (gateRegex: RegExp) => {
	return (val: string) => val == '' || gateRegex.test(val);
};

const zeroPlacesGate = createDecimalGate(/^\d*$/);
const onePlaceGate = createDecimalGate(/^\d*(\.)?(\d)?$/);
const twoPlacesGate = createDecimalGate(/^\d*(\.)?(\d{1,2})?$/);
const threePlacesGate = createDecimalGate(/^\d*(\.)?(\d{1,3})?$/);

// use cached functions for the the common decimal places so we don't need to generate
// 100s of functions for 100s of questionnaire questions when we can just reuse them.
export const createDecimalInputGateFunction = (maxDecimalPlaces: number | null | undefined) => {
	const decimalPlacesToUse = maxDecimalPlaces ?? defaultMaxDecimalPlaces;

	switch (decimalPlacesToUse) {
		case 0:
			return zeroPlacesGate;
		case 1:
			return onePlaceGate;
		case 2:
			return twoPlacesGate;
		case 3:
			return threePlacesGate;
		default: {
			const regexString = `^\\d*(\\.)?(\\d{1,${decimalPlacesToUse}})?$`;
			return createDecimalGate(new RegExp(regexString));
		}
	}
};

@Directive({
	standalone: true,
	selector: '[gatedInput]'
})
export class GatedInputDirective {
	public gateFunction = input.required<(inputValue: string) => boolean>();

	constructor(private el: ElementRef) {}

	@HostListener('beforeinput', ['$event'])
	onBeforeInputChange(event: InputEvent) {
		if (!event.data) {
			return;
		}
		const inputElement = this.el.nativeElement as HTMLInputElement;
		const currentValue = inputElement.value ?? '';
		const selectionStart = inputElement.selectionStart || 0;
		const selectionEnd = inputElement.selectionEnd || 0;

		// Simulate what the value will be after the input completes
		const newValue =
			currentValue.substring(0, selectionStart) + event.data + currentValue.substring(selectionEnd);

		// Check the new value against the regex to see if it's good
		const gate = this.gateFunction();
		if (!gate(newValue)) {
			event.preventDefault();
		}
	}
}
