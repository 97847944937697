import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { computed, effect, Injectable, signal } from '@angular/core';
import uniqueId from 'lodash-es/uniqueId';
import { AppLoaderComponent } from '../app-loader.component';

const minimumLoadTime = 100;

@Injectable({
	providedIn: 'root'
})
export class AppLoaderService {
	public readonly overlayRef: OverlayRef;
	private loadingProcesses = signal<string[]>([]);
	public isProcessing = computed<boolean>(() => {
		return this.loadingProcesses().length > 0;
	});

	constructor(private _overlay: Overlay) {
		this.overlayRef = this._overlay.create({
			positionStrategy: this._overlay.position().global().centerHorizontally().centerVertically(),
			hasBackdrop: true
		});

		effect(() => {
			const isProcessing = this.isProcessing();
			if (isProcessing && !this.overlayRef.hasAttached()) {
				this.overlayRef.attach(new ComponentPortal(AppLoaderComponent));
			}

			if (!isProcessing && this.overlayRef.hasAttached()) {
				this.overlayRef.detach();
			}
		});
	}

	// The setTimeout hack is so we can call these functions inside of effect()

	public startLoadProcess(selectedProcessId?: string): string {
		const processId = selectedProcessId ?? uniqueId();
		setTimeout(() => {
			this.loadingProcesses.update(old => {
				const next = [...old];
				const index = next.indexOf(processId);
				if (index == -1) {
					next.push(processId);
				}
				return next;
			});
		}, 0);
		return processId;
	}

	public completeLoadProcess(processId: string) {
		setTimeout(() => {
			this.loadingProcesses.update(old => {
				const next = [...old];
				const index = next.indexOf(processId);
				if (index > -1) {
					next.splice(index, 1);
				}
				return next;
			});
		}, minimumLoadTime);
	}

	public clearAllLoadProcessess() {
		setTimeout(() => {
			this.loadingProcesses.set([]);
		}, minimumLoadTime);
	}
}
