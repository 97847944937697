import { CommonModule } from '@angular/common';
import { Component, input } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { BaseInputFormFieldComponent } from '../base-input-form-field';
import { ReadonlyTextAreaInputComponent } from '../readonly-textarea-input/readonly-textarea-input.component';

@Component({
	standalone: true,
	selector: 'text-area-input-form-field',
	templateUrl: './text-area-input-form-field.component.html',
	styleUrl: './text-area-input-form-field.component.scss',
	imports: [CommonModule, FormsModule, ReactiveFormsModule, MatInputModule, ReadonlyTextAreaInputComponent]
})
export class TextAreaInputFormFieldComponent extends BaseInputFormFieldComponent {
	public minRows = input<number>(4);
	public maxLength = input<number>();
	public readonly = input<boolean>(false);

	constructor() {
		super('The value');
	}
}
