@if (showFormField) {
	<div class="form-field-wrapper">
		@if (readonly()) {
			<readonly-display-input
				[ngClass]="{ 'full-width': fullWidth }"
				[label]="label"
				[value]="visibleControl.value?.toString()"
				[suffix]="weightType" />
			<readonly-display-input [value]="otherValueDisplay()" [suffix]="otherWeightType" />
		} @else {
			<mat-form-field [ngClass]="{ 'full-width': fullWidth, short: short }">
				@if (label) {
					<mat-label style="white-space: nowrap">{{ label }}</mat-label>
				}
				@if (focusInitial) {
					<input
						matInput
						[placeholder]="placeHolder ? placeHolder : ''"
						[formControl]="visibleControl"
						maxLength="7"
						cdkFocusInitial
						gatedInput
						[gateFunction]="weightGate()" />
				} @else {
					<input
						matInput
						[placeholder]="placeHolder ? placeHolder : ''"
						[formControl]="visibleControl"
						maxLength="7"
						weight
						gatedInput
						[gateFunction]="weightGate()" />
				}
				<span class="suffix" matSuffix>{{ weightType }} </span>
			</mat-form-field>
			<readonly-display-input [value]="otherValueDisplay()" [suffix]="otherWeightType" />
		}
	</div>

	@if (formCtrl && formCtrl.hasError('required')) {
		<mat-error>{{ errorLabelToLowerExceptFirst }} is required.</mat-error>
	}

	@if (formCtrl && formCtrl.hasError('temperatureInvalidRange')) {
		<mat-error>{{ errorLabelToLowerExceptFirst }} is an invalid range.</mat-error>
	}

	<ng-template ngFor let-customError [ngForOf]="customErrors" ngProjectAs="mat-error">
		@if (formCtrl && formCtrl.hasError(customError.type)) {
			<mat-error>{{ customError.message }}</mat-error>
		}
	</ng-template>
}
