/* eslint-disable @typescript-eslint/no-explicit-any */
import { CommonModule } from '@angular/common';
import { Component, computed, effect, input, output } from '@angular/core';
import { AbstractControl, FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatCardModule } from '@angular/material/card';
import { MatSliderModule } from '@angular/material/slider';
import { distinctUntilChanged, map, Observable, of, startWith } from 'rxjs';
import {
	AnswerOptionTypeEnum,
	CodeableConceptSearchParametersApiModel,
	CodingApiModel,
	CustomInputControlTypeApiEnum,
	IdLabelApiModel,
	QuantityApiModel,
	QuestionnaireItemApiModel,
	QuestionnaireItemTypeApiEnum
} from '../../../../generated-models';
import {
	createDecimalInputGateFunction,
	GatedInputDirective
} from '../../../directives/gated-input.directive';
import { SiteWideDataService } from '../../../services/site-wide-data-service.service';
import { CheckboxGroupInputFormFieldComponent } from '../../input-form-fields/checkbox-group-input-form-field/checkbox-group-input-form-field.component';
import { CheckboxInputFormFieldComponent } from '../../input-form-fields/checkbox-input-form-field/checkbox-input-form-field.component';
import { DateInputFormFieldComponent } from '../../input-form-fields/date-input-form-field';
import { DateTimeInputFormFieldComponent } from '../../input-form-fields/date-time-input-form-field';
import { RadioGroupInputFormFieldComponent } from '../../input-form-fields/radio-group-input-form-field/radio-group-input-form-field.component';
import { ReadonlyDisplayInputComponent } from '../../input-form-fields/readonly-display-input/readonly-display-input.component';
import { SelectInputFormFieldComponent } from '../../input-form-fields/select-input-form-field';
import { TemperatureInputFormFieldComponent } from '../../input-form-fields/temperature-input-form-field';
import { TextAreaInputFormFieldComponent } from '../../input-form-fields/text-area-input-form-field';
import { TextInputFormFieldComponent } from '../../input-form-fields/text-input-form-field';
import { TimeInputFormFieldComponent } from '../../input-form-fields/time-input-form-field/time-input-form-field.component';
import { WeightInputFormFieldComponent } from '../../input-form-fields/weight-input-form-field';
import { ListItem } from '../../list-builder/list-builder.component';

import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import sum from 'lodash-es/sum';
import { CodeableConceptService } from '../../../services/codeable-concept.service';
import { FhirTerminologyService } from '../../../services/fhir-terminology-service.service';
import {
	ChipMultiSelectTypeaheadInputFormFieldComponent,
	ChipTypeaheadOption
} from '../../input-form-fields/chip-multi-select-typeahead-input-form-field/chip-multi-select-typeahead-input-form-field.component';
import { QuestionnaireItemChangedEvent, QuestionnaireService } from '../questionnaire.service';

const internalMaxCharacterWidth = 30;

const internalLabelControls: (CustomInputControlTypeApiEnum | undefined)[] = [
	CustomInputControlTypeApiEnum.Slider
];
const externalLabelControls: (CustomInputControlTypeApiEnum | undefined)[] = [];

const internalLabelDataTypes: (QuestionnaireItemTypeApiEnum | undefined)[] = [
	QuestionnaireItemTypeApiEnum.Boolean
];
const externalLabelDataTypes: (QuestionnaireItemTypeApiEnum | undefined)[] = [
	QuestionnaireItemTypeApiEnum.Display
];

const integerOnlyGate = (val: string) => {
	return /^-?\d+$/.test(val);
};

@Component({
	selector: 'questionnaire-item',
	standalone: true,
	imports: [
		TextAreaInputFormFieldComponent,
		TextInputFormFieldComponent,
		SelectInputFormFieldComponent,
		DateInputFormFieldComponent,
		TimeInputFormFieldComponent,
		CommonModule,
		MatCardModule,
		TimeInputFormFieldComponent,
		CheckboxGroupInputFormFieldComponent,
		DateTimeInputFormFieldComponent,
		TemperatureInputFormFieldComponent,
		WeightInputFormFieldComponent,
		MatSliderModule,
		ReactiveFormsModule,
		ReadonlyDisplayInputComponent,
		GatedInputDirective,
		CheckboxInputFormFieldComponent,
		RadioGroupInputFormFieldComponent,
		ChipMultiSelectTypeaheadInputFormFieldComponent,
		MatFormFieldModule,
		FormsModule,
		MatInputModule
	],
	templateUrl: './questionnaire-item.component.html',
	styleUrl: './questionnaire-item.component.scss'
})
export class QuestionnaireItemFormFieldComponent {
	public item = input.required<QuestionnaireItemApiModel>();
	public readonly = input<boolean>(false);
	public visible = input<boolean>(true);

	public onChangeValue = output<QuestionnaireItemChangedEvent>();

	public QuestionnaireItemType = QuestionnaireItemTypeApiEnum;
	public CustomInputControlType = CustomInputControlTypeApiEnum;

	public decimalOnlyGateGenerator = createDecimalInputGateFunction;
	public integerOnlyGate = integerOnlyGate;

	public shouldRender = computed(() => {
		const encounter = this._siteWideDataService.selectedEncounter();
		const item = this.item();

		return this._questionnaireService.shouldItemRender(item, encounter);
	});

	public chiefComplaintDisplayValue = computed(() => {
		const cc = this._siteWideDataService.primaryTenantChiefComplaint();
		if (cc?.chiefComplaint == undefined) {
			return undefined;
		}
		return cc.chiefComplaint.snomedCTDescription;
	});

	public externalLabel = computed<string[] | null>(() => {
		const item = this.item();

		if (
			externalLabelDataTypes.includes(item.dataType) ||
			externalLabelControls.includes(item.customInputType) ||
			!item.text
		) {
			const textLines = item.text?.split('\n') ?? null;
			if (item.prefix && textLines?.length) {
				textLines[0] = `${item.prefix} ${textLines[0]}`;
			}
			return textLines;
		}

		// if there is no text, or is a special custom component, or special data type

		const textIsShort = item.text.length < internalMaxCharacterWidth;
		if (
			textIsShort ||
			internalLabelDataTypes.includes(item.dataType) ||
			internalLabelControls.includes(item.customInputType)
		) {
			return null;
		}

		const textLines = item.text?.split('\n') ?? null;
		if (item.prefix && textLines?.length) {
			textLines[0] = `${item.prefix} ${textLines[0]}`;
		}
		return textLines;
	});

	public internalLabel = computed<string | undefined>(() => {
		const item = this.item();
		const externalLabel = this.externalLabel();

		if (externalLabel) {
			return undefined;
		}

		if (item.prefix && item.text) {
			return `${item.prefix} ${item.text}`;
		} else {
			return item?.text ?? undefined;
		}
	});

	private getStartValueForRepeatingItems(
		item: QuestionnaireItemApiModel
	): number[] | string[] | ListItem[] | Date[] {
		const init = item.initial;
		if (init == undefined) {
			return [];
		}

		switch (item.dataType) {
			case QuestionnaireItemTypeApiEnum.Choice:
				return init.map((x: any) => {
					if (x?.code && x?.display && item.answerOptions) {
						return item.answerOptions.findIndex(
							answer => answer.value?.code == x.code.value || answer.value?.display == x.display.value
						);
					} else {
						return x.value as number;
					}
				});
			case QuestionnaireItemTypeApiEnum.Decimal:
			case QuestionnaireItemTypeApiEnum.Quantity:
			case QuestionnaireItemTypeApiEnum.Integer:
				return init.map((x: any) => x.value as number);
			case QuestionnaireItemTypeApiEnum.String:
			case QuestionnaireItemTypeApiEnum.Text:
				return init.map((x: any) => x.value as string);
			case QuestionnaireItemTypeApiEnum.Time:
			case QuestionnaireItemTypeApiEnum.Date:
			case QuestionnaireItemTypeApiEnum.DateTime:
				return init.map((x: any) => new Date(x.value as string));
			default:
				// Url - Won't ever use
				// Unknown - Wont ever use
				// Display - Won't have a value
				// Attachment - Won't ever use
				// Boolean - Boolean + repeats control doesn't make sense,
				//           We use Choice + repeats for this and get the same value
				// Group - handled in questionnaire-group-component
				// Question - Won't be anwersing questions with questions
				// OpenChoice - Will use Group + repeats instead
				// Reference - Not sure if we will ever use this
				return init.map((x: any) => x.value as string);
		}
	}

	private getStartValueForSingleItems(
		item: QuestionnaireItemApiModel
	): number | string | boolean | ListItem | Date | undefined {
		if (item.initial == undefined || item.initial.length == 0) {
			return undefined;
		}

		switch (item.dataType) {
			case QuestionnaireItemTypeApiEnum.Choice: {
				const startVal = item.initial[0];
				if (startVal?.code && startVal?.display && item.answerOptions) {
					return item.answerOptions.findIndex(
						answer => answer.value?.code == startVal.code.value || answer.value?.display == startVal.display.value
					);
				} else {
					return startVal.value as number;
				}
			}
			case QuestionnaireItemTypeApiEnum.Quantity:
			case QuestionnaireItemTypeApiEnum.Decimal:
			case QuestionnaireItemTypeApiEnum.Integer:
				return item.initial[0].value as number;
			case QuestionnaireItemTypeApiEnum.String:
			case QuestionnaireItemTypeApiEnum.Text:
				return item.initial[0].value as string;
			case QuestionnaireItemTypeApiEnum.Time:
			case QuestionnaireItemTypeApiEnum.Date:
			case QuestionnaireItemTypeApiEnum.DateTime:
				return new Date(item.initial[0].value as string);
			case QuestionnaireItemTypeApiEnum.Boolean:
				return item.initial[0].value as boolean;
			default:
				// Url - Won't ever use
				// Unknown - Wont ever use
				// Display - Won't have a value
				// Attachment - Won't ever use
				// Group - handled in questionnaire-group-component
				// Question - Won't be anwersing questions with questions
				// OpenChoice - Will use Group instead
				// Reference - Not sure if we will ever use this
				return item.initial[0].value as string;
		}
	}

	public formCtrl = computed<FormControl>(() => {
		const item = this.item();

		const startVal:
			| string
			| number
			| boolean
			| Date
			| ListItem
			| string[]
			| number[]
			| ListItem[]
			| Date[]
			| QuantityApiModel[]
			| undefined = item.repeats
			? this.getStartValueForRepeatingItems(item)
			: this.getStartValueForSingleItems(item);

		return new FormControl<any>({ value: startVal, disabled: false });
	});

	public options = computed<IdLabelApiModel[]>(() => {
		const item = this.item();
		const options = item.answerOptions;

		if (!options) {
			return [];
		}
		return options.map((option, index) => {
			let label: string = '';

			// Check if valueCoding is present and use its display or code as the label
			switch (option.valueType) {
				case AnswerOptionTypeEnum.Coding: {
					const valueCoding = option.value as CodingApiModel;
					label = valueCoding.display || valueCoding.code || '';
					break;
				}
				case AnswerOptionTypeEnum.Integer:
				case AnswerOptionTypeEnum.String:
					label = option.value as string;
					break;
				default: {
					const error = `AnswerOptionTypeEnum ${option.valueType} not supported`;
					console.error(error, this.item(), options);
					throw error;
				}
			}

			return {
				id: index,
				label: label
			};
		});
	});

	public useInlineRadioButton = computed<boolean>(() => {
		const item = this.item();
		const options = this.options();
		const readonly = this.readonly();

		if (readonly || item.customInputType == CustomInputControlTypeApiEnum.Dropdown) {
			return false;
		}
		const characterCount = sum(options.map(x => x.label?.length ?? 0));
		const canFitAsTwoOptions = options.length == 2 && characterCount <= 25;
		const canFitAsThreeOptions = options.length == 3 && characterCount <= 20;
		return (
			item.dataType == QuestionnaireItemTypeApiEnum.Choice &&
			(canFitAsTwoOptions || canFitAsThreeOptions) &&
			!item.repeats
		);
	});

	public codeableConceptOptionsFilter = (value: string): Observable<ChipTypeaheadOption[]> => {
		if (value) {
			const searchParameters: CodeableConceptSearchParametersApiModel = {
				nameFilter: value
			};

			return this._codeableConceptService.searchCodeableConcepts(searchParameters).pipe(
				map(response => {
					if (response.ok) {
						return (
							response.body?.items?.map(m => {
								return {
									key: m.id.toString(),
									label: m.name
								} as ChipTypeaheadOption;
							}) ?? []
						);
					} else {
						return [];
					}
				})
			);
		} else {
			const val = this.formCtrl().value;
			return of(val ? [val] : []);
		}
	};

	public fhirTerminologyConceptSetOptionsFilter = (value: string): Observable<ChipTypeaheadOption[]> => {
		if (value) {
			const searchParameters: CodeableConceptSearchParametersApiModel = {
				nameFilter: value
			};
			return this._fhirTerminologyService.fetchValueSet(searchParameters).pipe(
				map(response => {
					if (response.ok) {
						return (
							response.body?.map(m => {
								return {
									key: m.key?.toString(),
									label: m.label
								} as ChipTypeaheadOption;
							}) ?? []
						);
					} else {
						return [];
					}
				})
			);
		} else {
			const val = this.formCtrl().value;
			return of(val ? [val] : []);
		}
	};

	public abstractControlToFormControl(ctrl: AbstractControl | null): FormControl {
		return ctrl as FormControl;
	}

	public constructor(
		private _siteWideDataService: SiteWideDataService,
		private _questionnaireService: QuestionnaireService,
		private _codeableConceptService: CodeableConceptService,
		private readonly _fhirTerminologyService: FhirTerminologyService
	) {
		effect(onCleanUp => {
			const form = this.formCtrl();
			const item = this.item();
			let sub = undefined;
			if (form) {
				sub = form.valueChanges.pipe(startWith(form.value), distinctUntilChanged()).subscribe(val => {
					// The fhir questionnaires require array answers
					const mappedValue = val == null || val == undefined || Array.isArray(val) ? val : [val];
					this.onChangeValue.emit({ [item.linkId]: mappedValue });
				});
			}
			onCleanUp(() => {
				sub?.unsubscribe();
			});
		});
	}
}
