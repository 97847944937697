import { CommonModule } from '@angular/common';
import { Component, forwardRef, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';

import { MtxLoaderModule } from '@ng-matero/extensions/loader';
import { QrCodeModule } from 'ng-qrcode';
import { take, tap } from 'rxjs';
import { ValidateSetupTOTPMFARequestApiModel } from '../../../generated-models';
import { AppSidenavService, DATA_INJECTION_TOKEN } from '../../../layout';
import { BaseIsProcessingComponent, TextInputFormFieldComponent } from '../../../shared';
import { UserMFASettingsService } from '../user-mfa-settings.service';

export interface AddEditMFATOTPComponentData {
	isEdit: boolean;
}

@Component({
	standalone: true,
	selector: 'add-edit-mfa-totp-component',
	templateUrl: './add-edit-mfa-totp.component.html',
	styleUrl: './add-edit-mfa-totp.component.scss',
	imports: [
		CommonModule,
		MatIconModule,
		MatButtonModule,
		MatSidenavModule,
		MatInputModule,
		MatSelectModule,
		FormsModule,
		ReactiveFormsModule,
		forwardRef(() => TextInputFormFieldComponent),
		MtxLoaderModule,
		QrCodeModule
	]
})
export class AddEditMFATOTPComponent extends BaseIsProcessingComponent implements OnInit, OnDestroy {
	@Input()
	public data: AddEditMFATOTPComponentData | null = null;

	public formGroup: FormGroup = new FormGroup({
		totpConfirmationCode: new FormControl<string | null>(null, [Validators.required])
	});

	get totpConfirmationCodeCtrl() {
		return this.formGroup.get('totpConfirmationCode') as FormControl;
	}

	public secretUri: string | null = null;
	public secret: string | null = null;

	public showGenerateSecretPanel: boolean = false;

	constructor(
		@Inject(DATA_INJECTION_TOKEN)
		readonly _data: AddEditMFATOTPComponentData,
		private readonly _userMFASettingsService: UserMFASettingsService,
		public readonly appSidenavService: AppSidenavService
	) {
		super([_userMFASettingsService.isProcessing$]);

		this.isProcessing$
			.pipe(
				tap(isProcessing => {
					// eslint-disable-next-line @typescript-eslint/no-unused-expressions
					isProcessing
						? this.formGroup.disable({ emitEvent: false })
						: this.formGroup.enable({ emitEvent: false });
					this.appSidenavService._isProcessing.next(isProcessing);
				})
			)
			.subscribe();

		if (_data) {
			this.data = _data;
		}

		if (!this.data?.isEdit) {
			this.generateNewSecret();
		}
	}

	public ngOnInit(): void {
		this.populateForm();
	}

	public override ngOnDestroy(): void {
		super.ngOnDestroy();
	}

	public generateNewSecret(): void {
		this._userMFASettingsService
			.setupTOTPMFA()
			.pipe(
				take(1),
				tap(response => {
					if (response.ok && response.body) {
						this.showGenerateSecretPanel = true;
						this.secret = response.body.secret!;
						this.secretUri = response.body.secretUri!;
					}
				})
			)
			.subscribe();
	}

	public validateNewSecret(code: string): void {
		const request: ValidateSetupTOTPMFARequestApiModel = {
			code: code
		};

		this._userMFASettingsService
			.validateSetupTOTPMFA(request)
			.pipe(
				take(1),
				tap(response => {
					if (response.ok) {
						this.appSidenavService.closeSidenav();
					}
				})
			)
			.subscribe();
	}

	public save(): void {
		this.formGroup.markAllAsTouched();

		if (this.formGroup.valid) {
			this.validateNewSecret(this.totpConfirmationCodeCtrl.value);

			// if (this._data.isEdit) {
			// 	const addEditChiefComplaint: AddEditChiefComplaint = {
			// 		name: this.chiefComplaintNameCtrl.value,
			// 		bodyAreaIds: this.bodyAreaIdsCtrl.value
			// 	};
			// 	if (this.data?.chiefComplaint.id) {
			// 		this._chiefComplaintsAdminService
			// 			.editChiefComplaint(this.data.chiefComplaint.id, addEditChiefComplaint)
			// 			.pipe(
			// 				tap(response => {
			// 					if (response.ok) {
			// 						this.appSidenavService.closeSidenav(true);
			// 					}
			// 				})
			// 			)
			// 			.subscribe();
			// 	}
			// } else {
			// 	const addEditChiefComplaint: AddEditChiefComplaint = {
			// 		name: this.chiefComplaintNameCtrl.value,
			// 		bodyAreaIds: this.bodyAreaIdsCtrl.value
			// 	};
			// 	this._chiefComplaintsAdminService
			// 		.addChiefComplaint(addEditChiefComplaint)
			// 		.pipe(
			// 			tap(response => {
			// 				if (response.ok) {
			// 					this.appSidenavService.closeSidenav(true);
			// 				}
			// 			})
			// 		)
			// 		.subscribe();
			// }
		}
	}

	private populateForm(): void {
		// if (this.data?.phoneNumber) {
		// 	this.formGroup.patchValue(
		// 		{
		// 			//phoneNumber: this.data.phoneNumber
		// 		},
		// 		{ emitEvent: false }
		// 	);
		// }
	}
}
