/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpClient, HttpResponse } from '@angular/common/http';
import { computed, Injectable, Signal, signal, WritableSignal } from '@angular/core';
import cloneDeep from 'lodash-es/cloneDeep';
import { finalize, map, Observable } from 'rxjs';
import { BaseService } from '../..';
import {
	AssessmentQuestionnairesApiModel,
	AssessmentQuestionnairesHistoryApiModel,
	ChiefComplaintTypeEnum,
	EnableWhenBehavior,
	EncounterApiModel,
	GenderApiEnum,
	GetQuestionnairesBodyApiModel,
	InsytsPageContextApiEnum,
	ProcedureApiModel,
	QuestionnaireApiModel,
	QuestionnaireEnableWhenApiModel,
	QuestionnaireItemApiModel,
	QuestionnaireItemTypeApiEnum,
	QuestionnaireResponseApiModel,
	QuestionnaireTitleApiEnum,
	SaveQuestionnaireApiModel
} from '../../../generated-models';
import { QuestionnaireTitleApiEnumDisplayMap } from '../../../generated-models/models/QuestionnaireTitleApiEnum';
import { ActionCardHeaderOptions, AppLoaderService, NotificationService } from '../../../layout';
import { SiteWideDataService } from '../../services/site-wide-data-service.service';

export type QuestionnaireGroupChangedEvent = Record<string, Record<string, unknown>[]>;

export type QuestionnaireItemChangedEvent = Record<string, unknown[]>;

export interface QuestionnaireItemVisibilityRow {
	linkId: string;
	visible: boolean;
}

type SupportedLogicOperations = 'NotEqual' | 'Equal';

export class QuestionnaireUIHelper {
	questionnaire: WritableSignal<QuestionnaireApiModel>;
	isValid: WritableSignal<boolean>;

	responses: WritableSignal<Record<string, any>>;
	hiddenState: WritableSignal<boolean | undefined>;
	headerOptions: Signal<ActionCardHeaderOptions | undefined>;

	constructor(questionnaire: QuestionnaireApiModel, hiddenState: boolean | undefined = undefined) {
		this.questionnaire = signal(questionnaire);
		this.isValid = signal(true);
		this.responses = signal({});
		this.hiddenState = signal(false);
		this.headerOptions = computed(() => {
			const hidden = this.hiddenState();
			if (hiddenState === undefined) {
				return undefined;
			}
			const opts: ActionCardHeaderOptions = {
				buttonColor: hidden === false ? 'warn' : 'primary',
				buttonIcon: hidden === false ? 'remove' : 'add'
			};

			return opts;
		});
	}

	updateValidity(validity: boolean) {
		this.isValid.set(validity);
	}

	updateResponses(responses: Record<string, unknown>) {
		this.responses.set(responses);
	}

	toggleHiddenState() {
		this.hiddenState.update(previousToggleState => !previousToggleState);
	}
}

@Injectable({
	providedIn: 'root'
})
export class QuestionnaireService extends BaseService<void> {
	constructor(
		_httpClient: HttpClient,
		_notificationService: NotificationService,
		private _appLoaderService: AppLoaderService,
		private _siteWideDataService: SiteWideDataService
	) {
		super('questionnaire', _httpClient, _notificationService);
	}

	private getQuestionnaireByUrl(
		url: string,
		nameForErrorMessage: string,
		useLoadSpinner: boolean = true
	): Observable<HttpResponse<QuestionnaireApiModel>> {
		let processId: string;
		if (useLoadSpinner) {
			processId = this._appLoaderService.startLoadProcess();
		}
		return this.get<QuestionnaireApiModel>(
			url,
			`Failed to retrieve the "${nameForErrorMessage}" questionnaire.`
		).pipe(
			finalize(() => {
				if (useLoadSpinner) {
					this._appLoaderService.completeLoadProcess(processId);
				}
			})
		);
	}

	public mapServerDictToRecord(
		response: HttpResponse<Record<string, QuestionnaireApiModel>>
	): Record<QuestionnaireTitleApiEnum, QuestionnaireApiModel> {
		const dict = response.body;
		const returnMe: Record<number | QuestionnaireTitleApiEnum, QuestionnaireApiModel> = {};
		if (!dict) {
			return returnMe;
		}
		for (const key in dict) {
			const v = dict[key];
			if (v.titleEnumValue) {
				returnMe[v.titleEnumValue] = v;
			}
		}
		return returnMe;
	}

	public getQuestionnaires(
		encounterId: string,
		types: QuestionnaireTitleApiEnum[],
		context?: InsytsPageContextApiEnum
	): Observable<Record<QuestionnaireTitleApiEnum, QuestionnaireApiModel>> {
		const processId = this._appLoaderService.startLoadProcess();

		const requestBody: GetQuestionnairesBodyApiModel = {
			types: types,
			context: context
		};

		return this.post<GetQuestionnairesBodyApiModel, Record<string, QuestionnaireApiModel>>(
			`getQuestionnaires/${encounterId}`,
			`Failed to retrieve the "${types.map(t => QuestionnaireTitleApiEnumDisplayMap[t]).join(', ')}" questionnaires.`,
			requestBody
		).pipe(
			map(this.mapServerDictToRecord),
			finalize(() => {
				this._appLoaderService.completeLoadProcess(processId);
			})
		);
	}

	public getBlankQuestionnaires(
		types: QuestionnaireTitleApiEnum[]
	): Observable<Record<QuestionnaireTitleApiEnum, QuestionnaireApiModel>> {
		const processId = this._appLoaderService.startLoadProcess();
		return this.post<QuestionnaireTitleApiEnum[], Record<string, QuestionnaireApiModel>>(
			`getBlankQuestionnaires`,
			`Failed to retrieve the "${types.map(t => QuestionnaireTitleApiEnumDisplayMap[t]).join(', ')}" questionnaires.`,
			types
		).pipe(
			map(this.mapServerDictToRecord),
			finalize(() => {
				this._appLoaderService.completeLoadProcess(processId);
			})
		);
	}

	public getBlankQuestionnairesByIdentifier(
		identifiers: string[]
	): Observable<HttpResponse<Record<string, QuestionnaireApiModel>>> {
		const processId = this._appLoaderService.startLoadProcess();
		return this.post<string[], Record<string, QuestionnaireApiModel>>(
			`getBlankQuestionnairesByIdentifier`,
			`Failed to retrieve the questionnaires.`,
			identifiers
		).pipe(
			finalize(() => {
				this._appLoaderService.completeLoadProcess(processId);
			})
		);
	}

	public getProcedureQuestionnaire(
		type: QuestionnaireTitleApiEnum,
		encounterId: string,
		procedureId: string
	): Observable<HttpResponse<QuestionnaireApiModel>> {
		const processId = this._appLoaderService.startLoadProcess();
		return this.get<QuestionnaireApiModel>(
			`getProcedureQuestionnaire?type=${type}&encounterId=${encounterId}&procedureId=${procedureId}`,
			`Failed to retrieve the questionnaire for procedure "procedureId"`
		).pipe(
			finalize(() => {
				this._appLoaderService.completeLoadProcess(processId);
			})
		);
	}

	public getQuestionnaireByResponseId(questionnaireResponseId: string) {
		const processId = this._appLoaderService.startLoadProcess();
		return this.get<QuestionnaireApiModel>(
			`getQuestionnaireByResponseId/${questionnaireResponseId}`,
			`Failed to retrieve the questionnaire`
		).pipe(
			finalize(() => {
				this._appLoaderService.completeLoadProcess(processId);
			})
		);
	}

	public getAssessmentQuestionnaires(
		context: InsytsPageContextApiEnum,
		reassessment: boolean,
		encounterId?: string
	): Observable<AssessmentQuestionnairesApiModel> {
		const processId = this._appLoaderService.startLoadProcess();

		let url = `getAssessmentQuestionnaires?context=${context}&reassessment=${reassessment}`;
		if (encounterId) {
			url += `&encounterId=${encounterId}`;
		}
		return this.get<AssessmentQuestionnairesApiModel>(
			url,
			`Failed to retrieve the assessment questionnaires.`
		).pipe(
			map(response => response.body as AssessmentQuestionnairesApiModel),
			finalize(() => {
				this._appLoaderService.completeLoadProcess(processId);
			})
		);
	}

	public getAssessmentHistory(
		encounterId: string,
		reassessment: boolean
	): Observable<AssessmentQuestionnairesHistoryApiModel[]> {
		const processId = this._appLoaderService.startLoadProcess();

		let url = `getAssessmentHistory/${encounterId}?reassessment=${reassessment}`;
		return this.get<AssessmentQuestionnairesHistoryApiModel[]>(
			url,
			`Failed to retrieve the assessment history`
		).pipe(
			map(response => response.body ?? []),
			finalize(() => {
				this._appLoaderService.completeLoadProcess(processId);
			})
		);
	}

	public getChiefComplaintHistoryOfPresentIllnessQuestionnaire(
		chiefComplaintId: string,
		encounterId?: string
	): Observable<HttpResponse<QuestionnaireApiModel>> {
		let url = `getHistoryOfPresentIllnessQuestionnaire?tenantChiefComplaintId=${encodeURIComponent(chiefComplaintId)}`;
		if (encounterId) {
			url += `&encounterId=${encounterId}`;
		}
		return this.getQuestionnaireByUrl(url, 'History of Present Illness Questionnaire');
	}

	public saveQuestionnaireResponses(
		model: SaveQuestionnaireApiModel
	): Observable<HttpResponse<Record<string, QuestionnaireApiModel>>> {
		return this.post<SaveQuestionnaireApiModel, Record<string, QuestionnaireApiModel>>(
			'saveQuestionnaireResponses',
			'Failed to save questionnaire response',
			model
		);
	}

	public getTestQuestionnaire(encounterId?: string): Observable<HttpResponse<QuestionnaireApiModel>> {
		let url = `getTestQuestionnaire`;
		return this.getQuestionnaireByUrl(url, 'Test');
	}

	public saveEncounterQuestionnairesAndCompleteProcedure(
		questionnaires: QuestionnaireUIHelper[],
		context: InsytsPageContextApiEnum,
		procedure: ProcedureApiModel
	): Observable<HttpResponse<ProcedureApiModel>> {
		const encounterId = this._siteWideDataService.selectedEncounterId();
		const patientId = this._siteWideDataService.selectedPatientId();

		if (!encounterId || !patientId) {
			throw 'There is no current encounter';
		}

		const questionnareResponses: QuestionnaireResponseApiModel[] = questionnaires
			.filter(helper => !helper.hiddenState())
			.map(helper => {
				const questionnaire = helper.questionnaire();
				const responses = helper.responses();
				return {
					id: questionnaire.questionnaireResponseId ?? null,
					questionnaireId: questionnaire.id as string,
					questionnaireVersion: questionnaire.versionId as string,
					responses: responses
				};
			});

		const model: SaveQuestionnaireApiModel = {
			patientId: patientId as string,
			encounterId: encounterId as string,
			context: context,
			associatedProcedureId: procedure.id,
			questionnaireResponses: questionnareResponses
		};

		return this.post<SaveQuestionnaireApiModel, ProcedureApiModel>(
			'saveQuestionnairesAndCompleteProcedure',
			'Failed to save questionnaire responses',
			model
		);
	}

	public saveQuestionnairesForCurrentEncounter(
		questionnaires: QuestionnaireUIHelper[],
		context: InsytsPageContextApiEnum,
		returnQuestionnaires?: boolean
	): Observable<HttpResponse<Record<string, QuestionnaireApiModel>>> {
		const encounterId = this._siteWideDataService.selectedEncounterId();
		const patientId = this._siteWideDataService.selectedPatientId();

		if (!encounterId || !patientId) {
			throw 'There is no current encounter';
		}

		const questionnareResponses: QuestionnaireResponseApiModel[] = questionnaires
			.filter(helper => !helper.hiddenState())
			.map(helper => {
				const questionnaire = helper.questionnaire();
				const responses = helper.responses();
				return {
					id: questionnaire.questionnaireResponseId ?? null,
					questionnaireId: questionnaire.id as string,
					questionnaireVersion: questionnaire.versionId as string,
					responses: responses
				};
			});

		const encounterQuestionnaireResponse: SaveQuestionnaireApiModel = {
			patientId: patientId as string,
			encounterId: encounterId as string,
			context: context,
			returnQuestionnaires: returnQuestionnaires,
			questionnaireResponses: questionnareResponses
		};

		return this.saveQuestionnaireResponses(encounterQuestionnaireResponse);
	}

	public getQuestionnaireVisibilityRows = (
		affectedItems: QuestionnaireItemApiModel[],
		allItems: QuestionnaireItemApiModel[],
		state: Record<string, unknown>
	): QuestionnaireItemVisibilityRow[] => {
		return affectedItems.map(item => {
			const logicItems = item.enableWhen as QuestionnaireEnableWhenApiModel[];
			const useAndLogic = item.enableBehavior == EnableWhenBehavior.All;

			const logicCallback = (logic: QuestionnaireEnableWhenApiModel): boolean => {
				// Default to showing an item if the logic data is broken
				if (!logic.question || !(logic.operator as SupportedLogicOperations)) {
					console.warn(
						`The logic for question ${item.linkId}: "${item.text}" is weird. Showing the question`,
						logic
					);
					return true;
				}

				const questionRef = allItems.find(questionnaireItem => questionnaireItem.linkId == logic.question);
				if (!questionRef) {
					console.warn(`there is no question with id ${logic.question}`);
					return false;
				}

				const op = logic.operator as SupportedLogicOperations;
				const testValues = state[logic.question] as unknown[];
				const answerOptionIndex = questionRef.answerOptions?.findIndex(option => {
					// handle "Coding" objects using duck typing
					if (option.value?.code && logic.answer?.code && option.value?.display && logic.answer?.display) {
						return (
							option.value.code == logic.answer.code.value || option.value.display == logic.answer.display.value
						);
					}
					return option.value == logic.answer?.value;
				});
				const isCodingLogic = !!logic.answer.code;

				// Does the EnableWhen answer match the current value?
				const valueMatches = isCodingLogic ? logic.answer.code == testValues : logic.answer.value == testValues;
				// Is the Enablewhen answer one of the values in current value array?
				const valueIncludes = testValues?.includes(logic.answer.value) ?? false;
				// If the Enablewhen answer is an index into the item.options list and that index matches
				const valueIncludesChoiceIndex = testValues?.includes(answerOptionIndex) ?? false;

				// Leaving this comment here to easily toggle for debugging
				/*
				console.log(`${item.text}`);
				console.log('logic.answer.value == testValues', logic.answer.value, '==', testValues);
				console.log('answerOptionIndex', answerOptionIndex);
				console.log(
					'valueMatches, valueIncludes, valueIncludesChoiceIndex',
					valueMatches,
					valueIncludes,
					valueIncludesChoiceIndex
				);
				console.log(`=`.repeat(40));
				//*/

				const result =
					(op == 'Equal' && (valueMatches || valueIncludes || valueIncludesChoiceIndex)) ||
					(op == 'NotEqual' && !valueMatches && !valueIncludes && !valueIncludesChoiceIndex);

				return result;
			};

			const resultBool = useAndLogic ? logicItems.every(logicCallback) : logicItems.some(logicCallback);
			return { linkId: item.linkId, visible: resultBool };
		});
	};

	public shouldItemRender(item: QuestionnaireItemApiModel, encounter: EncounterApiModel | null): boolean {
		if (!encounter) {
			return true;
		}
		const patientIsFemale = encounter.patient.gender == GenderApiEnum.Female;
		const primaryCC = encounter.chiefComplaints ? encounter.chiefComplaints[0] : undefined;
		const patientAge = this._siteWideDataService.getPatientAge(encounter.patient) ?? 99;
		const primaryCCIsInjury = !primaryCC || primaryCC.type == ChiefComplaintTypeEnum.Injury;
		return (
			(!item.feminineOnly || patientIsFemale) &&
			(!item.injuryOnly || primaryCCIsInjury) &&
			patientAge >= (item.minAge ?? 0) &&
			patientAge <= (item.maxAge ?? 200)
		);
	}

	// TODO: probably really broken. Not a problem because I'm not using it yet
	public generateFilledOutQuestionnaire(
		item: QuestionnaireApiModel,
		responses: Record<string, any>
	): QuestionnaireApiModel {
		const next = cloneDeep(item);
		this.fillQuestionnaireItems(next.item, responses);
		return next;
	}

	private fillQuestionnaireItems(
		items: QuestionnaireItemApiModel[] | undefined | null,
		responses: Record<string, any>
	): void {
		items?.forEach(item => {
			if (responses.hasOwnProperty(item.linkId)) {
				const response = responses[item.linkId];
				if (response !== undefined) {
					console.log(item, responses);
					if (item.dataType == QuestionnaireItemTypeApiEnum.Group) {
						if (Array.isArray(response) && response.length > 0) {
							// TODO: this is broken. It won't work for multi groups
							this.fillQuestionnaireItems(item.item, response[0] as Record<string, any>);
						} else {
							this.fillQuestionnaireItems(item.item, response as Record<string, any>);
						}
					} else {
						item.initial = Array.isArray(response)
							? response.map(x => {
									return { value: x };
								})
							: [{ value: response }];
					}
				}
			}
		});
	}
}
