import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { ChipTypeaheadOptionApiModel, CodeableConceptSearchParametersApiModel } from '../../generated-models';
import { NotificationService } from '../../layout';
import { BaseService } from './base-service.service';

@Injectable({
	providedIn: 'root'
})
export class FhirTerminologyService extends BaseService<FhirTerminologyService> {
	private valueSetLoadingSubject = new BehaviorSubject<boolean>(false);
	valueSetLoadingSubject$ = this.valueSetLoadingSubject.asObservable();

	//private valueSetSubject = new BehaviorSubject<CodeAp>(false);
	//valueSetSubject$ = this.valueSetSubject.asObservable();

	private narrativeLoadingSubject = new BehaviorSubject<boolean>(false);
	narrativeLoadingSubject$ = this.narrativeLoadingSubject.asObservable();

	private narrativevalueSetSubject = new BehaviorSubject<boolean>(false);
	narrativevalueSetSubject$ = this.narrativevalueSetSubject.asObservable();

	constructor(_httpClient: HttpClient, _notificationService: NotificationService) {
		super('fhirTerminology', _httpClient, _notificationService);
	}

	fetchValueSet(
		valueSetName: CodeableConceptSearchParametersApiModel
	): Observable<HttpResponse<ChipTypeaheadOptionApiModel[]>> {
		return this._httpClient.get<HttpResponse<ChipTypeaheadOptionApiModel[]>>(
			`/api/fhirTerminology/value-set/${valueSetName.nameFilter}`
		);
	}

	fetchNarrative(encounterId: string): void {
		this._httpClient.get<HttpResponse<ChipTypeaheadOptionApiModel[]>>(
			`/api/fhirTerminology/${encounterId}/narrative`
		);
	}
}
