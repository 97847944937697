<mtx-loader
	class="form-panel"
	[loading]="isProcessing$ | async"
	type="progressbar"
	color="primary"
	[hasBackdrop]="true">
	<form [formGroup]="formGroup">
		<div class="form-content">
			<select-input-form-field
				label="Practitioner Role"
				[formCtrl]="practitionerRoleIdCtrl"
				fullWidth
				focusInitial
				[keyLabelOptions]="testModeBaseData?.practitionerRoleOptions ?? []"></select-input-form-field>

			<select-input-form-field
				label="Permissions"
				[keyLabelOptions]="testModeBaseData?.permissionClaimOptions ?? []"
				[formCtrl]="permissionClaimIdsCtrl"
				multiple
				fullWidth>
			</select-input-form-field>
		</div>

		<div class="action-buttons">
			<button mat-flat-button (click)="save()" [disabled]="isProcessing$ | async">
				<mat-icon aria-hidden="false">save</mat-icon>Save
			</button>
		</div>
	</form>
</mtx-loader>
