@if (showFormField) {
	@if (label) {
		<label>{{ label }}</label>
	}
	<mat-radio-group aria-label="" [formControl]="formCtrl" [ngClass]="{ vertical: vertical() }">
		@for (option of options ?? []; track option.id) {
			<mat-radio-button [value]="option.id">{{ option.label }}</mat-radio-button>
		}
	</mat-radio-group>
}
