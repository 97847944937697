<mtx-loader
	class="form-panel"
	[loading]="isProcessing$ | async"
	type="progressbar"
	color="primary"
	[hasBackdrop]="true">
	<form [formGroup]="formGroup">
		<div class="form-content">
			<select-input-form-field
				label="Facility"
				[formCtrl]="facilityIdCtrl"
				fullWidth
				focusInitial
				[options]="facilities"></select-input-form-field>
		</div>

		<div class="action-buttons">
			<button type="submit" mat-flat-button (click)="save()" [disabled]="isProcessing$ | async">
				<mat-icon aria-hidden="false">domain</mat-icon>Change Facility
			</button>
		</div>
	</form>
</mtx-loader>
