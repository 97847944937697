import { Component } from '@angular/core';
import { MatDialog, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { MatIconRegistry } from '@angular/material/icon';
import { ChildrenOutletContexts, EventType, Router, RouterModule } from '@angular/router';
import { takeUntil, tap } from 'rxjs';
import { AuthService } from './auth';
import {
	SessionExpiringSoonDialogComponent,
	SessionExpiringSoonDialogComponentData
} from './auth/session-expiring-soon-dialog';
import { AppLoaderService, ConfirmationComponent, NotificationToastComponent } from './layout';
import { BaseComponent } from './shared';

@Component({
	standalone: true,
	selector: 'root',
	templateUrl: './app.component.html',
	styleUrl: './app.component.scss',
	animations: [],
	imports: [NotificationToastComponent, ConfirmationComponent, RouterModule, MatDialogModule]
})
export class AppComponent extends BaseComponent {
	private _sessionExpiringSoonDialogRef: MatDialogRef<SessionExpiringSoonDialogComponent> | null = null;

	constructor(
		_matIconReg: MatIconRegistry,
		private _router: Router,
		private _appLoaderService: AppLoaderService,
		private _dialog: MatDialog,
		private _authService: AuthService,
		private _contexts: ChildrenOutletContexts
	) {
		super();
		_matIconReg.setDefaultFontSetClass('material-symbols-outlined');

		/** listen for route navigation changes. */
		this._router.events.pipe(takeUntil(this._destroy)).subscribe(e => {
			this.navigationInterceptor(e);
		});

		this._authService.$showSessionExpiringSoon
			.pipe(
				takeUntil(this._destroy),
				tap((sessionTimeLeft: number) => {
					if (sessionTimeLeft > 0) {
						this.openOrUpdateSessionExpiringSoonDialog(sessionTimeLeft);
					} else {
						this.closeSessionExpiringSoonDialog();
					}
				})
			)
			.subscribe();
	}

	getRouteAnimationData() {
		return this._contexts.getContext('primary')?.route?.snapshot?.data?.['animation'];
	}

	/** Shows and hides the loading spinner during RouterEvent changes. */
	private navigationInterceptor(event: any): void {
		switch (event.type) {
			case EventType.NavigationStart:
				this._appLoaderService.startLoadProcess(event.id);
				break;
			case EventType.NavigationEnd:
			case EventType.NavigationCancel:
			case EventType.NavigationError:
				this._appLoaderService.completeLoadProcess(event.id);
				break;
			default:
				break;
		}
	}

	/** Show a dialog if the session is going to expire soon. */
	private openOrUpdateSessionExpiringSoonDialog(sessionTimeLeftMS: number): void {
		if (!this._sessionExpiringSoonDialogRef) {
			this._sessionExpiringSoonDialogRef = this._dialog.open(SessionExpiringSoonDialogComponent, {
				data: {
					sessionTimeLeftMS: sessionTimeLeftMS
				} as SessionExpiringSoonDialogComponentData,
				disableClose: true
			});
		} else {
			if (this._sessionExpiringSoonDialogRef.componentInstance) {
				this._sessionExpiringSoonDialogRef.componentInstance.data = {
					sessionTimeLeftMS: sessionTimeLeftMS
				} as SessionExpiringSoonDialogComponentData;
			} else {
				this.closeSessionExpiringSoonDialog();
			}
		}
	}

	private closeSessionExpiringSoonDialog(): void {
		if (this._sessionExpiringSoonDialogRef) {
			this._sessionExpiringSoonDialogRef.close();
			this._sessionExpiringSoonDialogRef = null;
		}
	}
}
