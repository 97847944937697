/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum OrderRestrictionApiEnum {
    None = 0,
    PerProtocol = 1,
    PerVerbal = 2,
}

export const OrderRestrictionApiEnumDisplayMap : { [key in OrderRestrictionApiEnum] : string } = {
    [OrderRestrictionApiEnum.None]: "None",
    [OrderRestrictionApiEnum.PerProtocol]: "PerProtocol",
    [OrderRestrictionApiEnum.PerVerbal]: "PerVerbal",
}
