@if (label) {
	<fieldset>
		<legend>{{ label }}</legend>
		<ng-container *ngTemplateOutlet="checkboxGroupContent" />
	</fieldset>
} @else {
	<ng-container *ngTemplateOutlet="checkboxGroupContent" />
}

<ng-template #checkboxGroupContent>
	@if (readonly()) {
		@let selectedOpts = getSelectedOptions();
		@if (selectedOpts.length == 0) {
			<readonly-display-input [value]="undefined" />
		} @else {
			<div style="display: flex; gap: 10px; flex-wrap: wrap">
				@for (opt of selectedOpts; track opt.id) {
					<readonly-display-input [value]="opt.label" />
				}
			</div>
		}
	} @else {
		@for (option of options(); track option.id) {
			<mat-checkbox
				[ngStyle]="{ 'min-width': columnWidth() }"
				[checked]="isChecked(option)"
				(change)="onCheckboxChange($event, option.id)">
				{{ option.label }}
			</mat-checkbox>
		}
	}
</ng-template>
