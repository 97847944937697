import { Component, computed, effect, input, output, untracked } from '@angular/core';
import { FormControl } from '@angular/forms';
import { combineLatest, distinctUntilChanged, startWith } from 'rxjs';
import { CustomInputControlTypeApiEnum, QuestionnaireItemApiModel } from '../../../../generated-models';
import { QuestionnaireGroupChangedEvent } from '../../questionnaire-form/questionnaire.service';
import { HeightInputFormFieldComponent } from '../height-input-form-field/height-input-form-field.component';
import { ReadonlyDisplayInputComponent } from '../readonly-display-input/readonly-display-input.component';
import { WeightInputFormFieldComponent } from '../weight-input-form-field/weight-input-form-field.component';

@Component({
	selector: 'body-measurement-component',
	standalone: true,
	imports: [WeightInputFormFieldComponent, HeightInputFormFieldComponent, ReadonlyDisplayInputComponent],
	templateUrl: './body-measurement-component.component.html',
	styleUrls: ['./body-measurement-component.component.scss']
})
export class BodyMeasurementComponent {
	public group = input.required<QuestionnaireItemApiModel>();
	public readonly = input<boolean>(false);
	public onChangeValue = output<QuestionnaireGroupChangedEvent>();

	public weightItem = computed(() => {
		const group = this.group();
		if (!group) {
			console.warn('Group is undefined when computing weightItem.');
			return null;
		}
		return this.getChildItem(group, CustomInputControlTypeApiEnum.Weight);
	});

	public weightForm = computed(() => {
		const weightItem = this.weightItem();
		let initialWeight: number | null = null;
		if (weightItem?.initial && weightItem?.initial?.length > 0 && weightItem.initial[0].value) {
			initialWeight = weightItem.initial[0].value as number;
		}
		return new FormControl<number | null>({ value: initialWeight, disabled: this.readonly() });
	});

	public heightItem = computed(() => {
		const group = this.group();
		if (!group) {
			console.warn('Group is undefined when computing heightItem.');
			return null;
		}
		return this.getChildItem(group, CustomInputControlTypeApiEnum.Height);
	});

	public heightForm = computed(() => {
		const heightItem = this.heightItem();
		let initialHeight: number | null = null;
		if (heightItem?.initial && heightItem?.initial?.length > 0 && heightItem.initial[0].value) {
			initialHeight = heightItem.initial[0].value as number;
		}
		return new FormControl<number | null>({ value: initialHeight, disabled: this.readonly() });
	});

	public BMIItem = computed(() => {
		const group = this.group();
		if (!group) {
			console.warn('Group is undefined when computing BMIItem.');
			return null;
		}
		return this.getChildItem(group, CustomInputControlTypeApiEnum.BMI);
	});

	public BMIForm = computed(() => {
		const bmiItem = this.BMIItem();
		let initialBMI: string | null = null;
		if (bmiItem?.initial && bmiItem?.initial?.length > 0 && bmiItem.initial[0].value) {
			initialBMI = (bmiItem.initial[0].value as number).toString();
		}
		return new FormControl<string | null>({ value: initialBMI, disabled: this.readonly() });
	});

	private getChildItem(group: QuestionnaireItemApiModel, ctrlType: CustomInputControlTypeApiEnum) {
		if (!group?.item) {
			console.error('Group or its items are not defined.');
			throw `Could not find ${ctrlType} in the items of the Body Measurement Group`;
		}

		const childItem = group.item.find(child => child.customInputType === ctrlType);
		if (!childItem) {
			console.error(`No item found with control type: ${ctrlType}`);
			throw `Could not find ${ctrlType} in the items of the Body Measurement Group`;
		}

		return childItem;
	}

	constructor() {
		effect(onCleanup => {
			const weightForm = this.weightForm();
			const heightForm = this.heightForm();
			const BMIForm = this.BMIForm();

			const group = untracked(this.group);
			const weightItem = untracked(this.weightItem);
			const heightItem = untracked(this.heightItem);
			const BMIItem = untracked(this.BMIItem);

			// Create a single subscription
			const sub = combineLatest([
				weightForm.valueChanges.pipe(startWith(weightForm.value), distinctUntilChanged()),
				heightForm.valueChanges.pipe(startWith(heightForm.value), distinctUntilChanged())
			]).subscribe(([weight, height]: [number | null, number | null]) => {
				let bmiRounded: number | null = null;
				if (height != null && weight != null) {
					const heightInMeters = height / 100;
					const bmi = weight / (heightInMeters * heightInMeters);
					bmiRounded = parseFloat(bmi.toFixed(this.BMIItem()?.maxDecimalPlaces ?? 3));
				}

				BMIForm.setValue(bmiRounded?.toString() ?? null);

				const updatedGroup: QuestionnaireGroupChangedEvent = {
					[group?.linkId ?? '']: [
						{
							[weightItem?.linkId ?? '']: [weight],
							[heightItem?.linkId ?? '']: [height],
							[BMIItem?.linkId ?? '']: [bmiRounded]
						}
					]
				};
				this.onChangeValue.emit(updatedGroup);
			});

			onCleanup(() => {
				sub.unsubscribe();
			});
		});
	}
}
