<fieldset>
	<legend>{{ group().text }}</legend>
	<weight-input-form-field
		fullWidth
		short
		[formCtrl]="weightForm()"
		[readonly]="readonly()"
		[label]="weightItem()?.text"
		[maxDecimalPlaces]="weightItem()?.maxDecimalPlaces"
		weightType="lbs" />
	<height-input-form-field
		fullWidth
		short
		[formCtrl]="heightForm()"
		[readonly]="readonly()"
		[maxDecimalPlaces]="heightItem()?.maxDecimalPlaces"
		[label]="heightItem()?.text" />
	<readonly-display-input [label]="BMIItem()?.text ?? null" [value]="BMIForm().value" />
</fieldset>
