import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { CommonModule } from '@angular/common';
import {
	AfterViewInit,
	ChangeDetectionStrategy,
	Component,
	forwardRef,
	Input,
	OnDestroy,
	OnInit
} from '@angular/core';
import {
	ControlValueAccessor,
	FormControl,
	FormGroup,
	FormsModule,
	NG_VALUE_ACCESSOR,
	ReactiveFormsModule,
	Validators
} from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MtxSelect } from '@ng-matero/extensions/select';
import { filter, Observable, of, startWith, Subject, switchMap, takeUntil, tap } from 'rxjs';
import { BaseComponent } from '../base-component.component';

export interface SelectTypeaheadOption {
	key: string | null;
	label: string | null | undefined;
	isFavorite?: boolean | null | undefined;
}

@Component({
	standalone: true,
	changeDetection: ChangeDetectionStrategy.OnPush,
	selector: 'select-typeahead',
	templateUrl: './select-typeahead.component.html',
	styleUrl: './select-typeahead.component.scss',
	imports: [CommonModule, FormsModule, ReactiveFormsModule, MatFormFieldModule, MatIconModule, MtxSelect],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => SelectTypeaheadComponent),
			multi: true
		}
	]
})
export class SelectTypeaheadComponent
	extends BaseComponent
	implements ControlValueAccessor, OnInit, AfterViewInit, OnDestroy
{
	private _fullWidth: boolean = false;
	private _hideIfDisabled: boolean = false;
	private _minTypeaheadLength: number = 0;
	private _multiple: boolean = false;
	private _disabled: boolean = false;
	private _hideErrorText: boolean = false;
	private _loading: boolean = false;

	protected onTouched!: () => void;
	protected onChange!: (value: SelectTypeaheadOption | SelectTypeaheadOption[] | null | undefined) => void;

	@Input()
	public placeHolder: string | null | undefined = null;

	@Input()
	public label: string | null | undefined = null;

	@Input({ required: true })
	public optionsFilterCallBack!: (value: string) => Observable<SelectTypeaheadOption[]>;

	@Input()
	public loadFavoriteOptionsCallBack: (() => Observable<SelectTypeaheadOption[]>) | null = null;

	@Input()
	set minTypeaheadLength(value: number) {
		this._minTypeaheadLength = value;
	}

	get minTypeaheadLength(): number {
		if (this._minTypeaheadLength === 0 && this.loadFavoriteOptionsCallBack !== null) {
			return 1;
		}

		return this._minTypeaheadLength;
	}

	@Input()
	set fullWidth(value: BooleanInput) {
		this._fullWidth = coerceBooleanProperty(value);
	}

	get fullWidth(): boolean {
		return this._fullWidth;
	}

	@Input()
	set hideIfDisabled(value: BooleanInput) {
		this._hideIfDisabled = coerceBooleanProperty(value);
	}

	get hideIfDisabled(): boolean {
		return this._hideIfDisabled;
	}

	@Input()
	set multiple(value: BooleanInput) {
		this._multiple = coerceBooleanProperty(value);
	}

	get multiple(): boolean {
		return this._multiple;
	}

	get disabled(): boolean {
		return this._disabled;
	}

	@Input()
	set hideErrorText(value: BooleanInput) {
		this._hideErrorText = coerceBooleanProperty(value);
	}

	get hideErrorText(): boolean {
		return this._hideErrorText;
	}

	@Input()
	set loading(value: BooleanInput) {
		this._loading = coerceBooleanProperty(value);
	}

	get loading(): boolean {
		return this._loading;
	}

	public showFormField(): boolean {
		if (this.hideIfDisabled && this.disabled) {
			return false;
		}

		return true;
	}

	public favoriteOptions: SelectTypeaheadOption[] = [];
	public filteredOptions: SelectTypeaheadOption[] = [];

	public selectedItems: SelectTypeaheadOption | SelectTypeaheadOption[] = [];

	public items: SelectTypeaheadOption[] = [];

	public typeahead = new Subject<string>();
	public typeahead$ = this.typeahead.asObservable();

	public readonly formGroup: FormGroup = new FormGroup({
		options: new FormControl<SelectTypeaheadOption | SelectTypeaheadOption[] | null>(null, [
			Validators.required
		])
	});

	get optionsCtrl(): FormControl<SelectTypeaheadOption | SelectTypeaheadOption[] | null> {
		return this.formGroup.get('options') as FormControl;
	}

	constructor() {
		super();
	}

	writeValue(obj: SelectTypeaheadOption | SelectTypeaheadOption[] | null | undefined): void {
		if (!obj) {
			this.items = [];
			this.selectedItems = [];
			this.optionsCtrl.reset(null, { emitEvent: true });
		} else {
			this.optionsCtrl.setValue(obj, { emitEvent: true });
			this.selectedItems = obj;
			this.items = Array.isArray(obj) ? obj : [obj];
		}
	}

	registerOnChange(
		fn: (values: SelectTypeaheadOption | SelectTypeaheadOption[] | null | undefined) => void
	): void {
		this.onChange = fn;
	}

	registerOnTouched(fn: () => void): void {
		this.onTouched = fn;
	}

	setDisabledState?(isDisabled: boolean): void {
		this._disabled = isDisabled;
	}

	public ngOnInit(): void {
		this.typeahead$
			.pipe(
				takeUntil(this._destroy),
				filter(f => typeof f === 'string'),
				filter(f => f?.length >= this.minTypeaheadLength),
				switchMap(value => this.optionsFilterCallBack(value)),
				tap(options => {
					this.items = options;
				})
			)
			.subscribe();

		this.typeahead$
			.pipe(
				takeUntil(this._destroy),
				startWith(''),
				filter(f => f === '' || f === null),
				filter(f => f?.length >= this.minTypeaheadLength),
				switchMap(value => this.loadFavorites()),
				tap(options => {
					this.items = options;
				})
			)
			.subscribe();
	}

	public ngAfterViewInit(): void {
		this.optionsCtrl.valueChanges
			.pipe(
				takeUntil(this._destroy),
				tap(value => {
					this.onChange(value);
				})
			)
			.subscribe();
	}

	public override ngOnDestroy(): void {
		this.typeahead.complete();
		super.ngOnDestroy();
	}

	public selectionChanged(value: SelectTypeaheadOption | SelectTypeaheadOption[]): void {
		this.optionsCtrl.setValue(value, { emitEvent: true });
		this.optionsCtrl.markAsTouched();
	}

	public compareWith = (a: SelectTypeaheadOption, b: SelectTypeaheadOption): boolean => {
		return a.key == b.key;
	};

	private loadFavorites(): Observable<SelectTypeaheadOption[]> {
		if (this.loadFavoriteOptionsCallBack) {
			return this.loadFavoriteOptionsCallBack();
		} else {
			return of([]);
		}
	}
}
