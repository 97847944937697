import { Component, computed, effect, input, output, signal } from '@angular/core';
import {
	CodingApiModel,
	CustomInputControlTypeApiEnum,
	QuestionnaireApiModel,
	QuestionnaireItemApiModel,
	QuestionnaireItemTypeApiEnum
} from '../../../generated-models';
import { ActionCardComponent, ActionCardHeaderOptions } from '../../../layout';
import { QuestionnaireFormGroupComponent } from './questionnaire-group/questionnaire-group.component';
import { QuestionnaireItemFormFieldComponent } from './questionnaire-item/questionnaire-item.component';

import { CommonModule, DatePipe } from '@angular/common';
import groupBy from 'lodash-es/groupBy';
import sum from 'lodash-es/sum';
import { AuthService } from '../../../auth';
import { ReadonlyDisplayInputComponent } from '../input-form-fields/readonly-display-input/readonly-display-input.component';
import {
	QuestionnaireGroupChangedEvent,
	QuestionnaireItemChangedEvent,
	QuestionnaireService
} from './questionnaire.service';

interface LinkIdToQuestionnaireItemApiModel {
	linkId: string;
	items: QuestionnaireItemApiModel[];
}

@Component({
	selector: 'questionnaire-form',
	standalone: true,
	imports: [
		QuestionnaireItemFormFieldComponent,
		ActionCardComponent,
		QuestionnaireFormGroupComponent,
		CommonModule,
		ReadonlyDisplayInputComponent
	],
	templateUrl: './questionnaire-form.component.html',
	styleUrl: './questionnaire-form.component.scss',
	providers: [DatePipe]
})
export class QuestionnaireFormComponent {
	public JSON = JSON;
	public readonly = input<boolean>(false);
	public questionnaire = input.required<QuestionnaireApiModel | undefined>();
	public titleOverride = input<string>();
	public showMetaInfo = input<boolean>(false);
	public headerStyle = input<undefined | 'blue'>();
	public fillVerticalSpace = input<boolean>(false);

	public desiredColumnWidth = input<number>(300);
	public componentWidth = signal<number>(1000);
	public hiddenState = input<boolean | undefined>(undefined);
	public explicitlyHiddenCodes = input<CodingApiModel[] | undefined>(undefined);
	public headerOptions = input<ActionCardHeaderOptions>();

	public onResponsesChanged = output<Record<string, unknown>>();
	// TODO: We are not handling validation of the questionnaire
	public onValidityChanged = output<boolean>();
	public onCornerButtonPressed = output();

	public ItemType = QuestionnaireItemTypeApiEnum;

	public groupState = signal<Record<string, unknown>>({});
	public explicitlyHiddenCodeSet = computed<Set<string>>(() => {
		const hiddenCodes = this.explicitlyHiddenCodes();
		return hiddenCodes ? new Set<string>(hiddenCodes.map(x => x.code ?? '')) : new Set<string>();
	});

	public displayColumns = computed<number | undefined>(() => {
		const questionnaire = this.questionnaire();
		if (questionnaire?.displayColumns === null || questionnaire?.displayColumns === undefined) {
			return undefined;
		}
		return questionnaire.displayColumns;
	});

	public lastUpdatedTime = computed(() => {
		const questionnaire = this.questionnaire();
		if (!questionnaire?.responseInfo) {
			return undefined;
		}

		return this._datePipe.transform(
			questionnaire.responseInfo.updatedTime,
			'M/d/yy, H:mm:ss',
			this.authService.userInfo()?.facilityTimeZoneUTCOffset
		);
	});

	public linkedQuestionnaireItems = computed<LinkIdToQuestionnaireItemApiModel[]>(() => {
		const questionnaire = this.questionnaire();

		const items = questionnaire?.item;
		if (!items) {
			return [];
		}
		const groupedItemsByLinkId = groupBy(questionnaire.item, item => item.linkId) as Record<
			string,
			QuestionnaireItemApiModel[]
		>;
		return Object.entries(groupedItemsByLinkId).map(([key, value]) => ({ linkId: key, items: value }));
	});

	public hidableItems = computed(() => {
		const questionnaire = this.questionnaire();
		return questionnaire?.item?.filter(item => item.enableWhen && item.enableWhen.length > 0) ?? [];
	});

	public visibleItems = signal<Record<string, boolean>>({});
	public CustomControlType = CustomInputControlTypeApiEnum;

	trackByItemId(_: number, item: LinkIdToQuestionnaireItemApiModel): any {
		const IGiveUP = item.linkId + item.items.flatMap(i => i.initial?.toString()).join();
		return IGiveUP;
	}

	public constructor(
		private _questionnaireService: QuestionnaireService,
		private readonly _datePipe: DatePipe,
		public readonly authService: AuthService
	) {
		effect(
			() => {
				const state = this.groupState();
				this.onResponsesChanged.emit(state);
			},
			{ allowSignalWrites: true }
		);
	}

	allColumnTitles(column: LinkIdToQuestionnaireItemApiModel[]) {
		return column.map(x => x.linkId).join(',');
	}

	groupItemsByLinkId(items: QuestionnaireItemApiModel[] | undefined): LinkIdToQuestionnaireItemApiModel[] {
		if (!items || !items.length) {
			return [];
		}
		const groupedItems = groupBy(items, item => item.linkId);
		return Object.entries(groupedItems).map(([key, value]) => ({ linkId: key, items: value }));
	}

	private countElements(items: QuestionnaireItemApiModel[] | undefined): number {
		if (!items) {
			return 0;
		}

		if (items.length == 0) {
			return 1;
		}

		return 1 + sum(items.map(item => this.countElements(item.item)));
	}

	public isExplicitlyHidden(item: QuestionnaireItemApiModel) {
		const hiddenCodes = this.explicitlyHiddenCodeSet();
		if (item.code && item.code.length && hiddenCodes.size > 0) {
			const hideMe = !!item.code.find(x => x.code && hiddenCodes.has(x.code));
			return hideMe;
		}
		return false;
	}

	public isChoiceGroup(item: QuestionnaireItemApiModel) {
		return item.dataType == QuestionnaireItemTypeApiEnum.Choice;
	}

	onItemChanged(event: QuestionnaireItemChangedEvent | QuestionnaireGroupChangedEvent) {
		const oldState = this.groupState();
		const state = { ...oldState };

		const eventKeys: string[] = [];
		for (const key in event) {
			state[key] = event[key];
			eventKeys.push(key);
		}

		const affectedItems = this.hidableItems().filter(item =>
			item.enableWhen?.some(logicItem => eventKeys.includes(logicItem.question as string))
		);

		const visibilityChanges = this._questionnaireService.getQuestionnaireVisibilityRows(
			affectedItems,
			this.questionnaire()?.item ?? [],
			state
		);

		this.visibleItems.update(old => {
			const next = { ...old };
			visibilityChanges.forEach(visiblityRow => {
				next[visiblityRow.linkId] = visiblityRow.visible;
			});
			return next;
		});

		this.groupState.set(state);
	}
}
