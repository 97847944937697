import { CommonModule } from '@angular/common';
import { Component, computed, input, Input, OnDestroy, OnInit, signal } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { distinctUntilChanged, Subject, takeUntil } from 'rxjs';
import {
	createDecimalInputGateFunction,
	GatedInputDirective
} from '../../../directives/gated-input.directive';
import { BaseInputFormFieldComponent } from '../base-input-form-field';
import { ReadonlyDisplayInputComponent } from '../readonly-display-input/readonly-display-input.component';

declare type WeightType = 'kg' | 'lbs';

const defaultWeightType: WeightType = 'kg';

@Component({
	standalone: true,
	selector: 'weight-input-form-field',
	templateUrl: './weight-input-form-field.component.html',
	styleUrl: './weight-input-form-field.component.scss',
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		MatInputModule,
		GatedInputDirective,
		ReadonlyDisplayInputComponent
	]
})
export class WeightInputFormFieldComponent extends BaseInputFormFieldComponent implements OnInit, OnDestroy {
	private _destroy: Subject<void> = new Subject();
	private _weightType: WeightType = defaultWeightType;

	public readonly = input<boolean>(false);
	public maxDecimalPlaces = input<number | undefined | null>(3);
	public otherValueDisplay = signal<string | null>(null);

	public visibleControl = new FormControl<number | null>({ value: null, disabled: false });

	public weightGate = computed(() => {
		const decimalPlacesToUse = this.maxDecimalPlaces();
		return createDecimalInputGateFunction(decimalPlacesToUse);
	});

	@Input({ required: true })
	set weightType(value: WeightType) {
		this._weightType = value;
	}

	@Input()
	public placeHolder: string | null | undefined = null;

	get weightType(): WeightType {
		return this._weightType;
	}

	get otherWeightType(): WeightType {
		return this._weightType == 'kg' ? 'lbs' : 'kg';
	}

	private convertLbsToKg(lbs: number | null): number | null {
		if (lbs == null) {
			return null;
		}
		const conversionFactor = 0.45359237;
		const convertedNumber = lbs * conversionFactor;

		const places = this.maxDecimalPlaces() ?? 3;
		return parseFloat(convertedNumber.toFixed(places));
	}

	private convertKgToLbs(kg: number | null): number | null {
		if (kg == null) {
			return null;
		}
		const conversionFactor = 2.2046226218;
		const convertedNumber = kg * conversionFactor;

		const places = this.maxDecimalPlaces() ?? 3;
		return parseFloat(convertedNumber.toFixed(places));
	}

	private syncEnabledStates() {
		if (this.formCtrl.enabled && !this.visibleControl.enabled) {
			this.visibleControl.enable();
		} else if (!this.formCtrl.enabled && this.visibleControl.enabled) {
			this.visibleControl.disable();
		}
	}

	// -- Object Life Cycle Functions
	constructor() {
		super('The value');
	}

	ngOnInit(): void {
		// Whenever the user types something into our visible control
		// we convert it to kg and put in into the formControl
		this.visibleControl.valueChanges
			.pipe(distinctUntilChanged(), takeUntil(this._destroy))
			.subscribe((value: string | number | null) => {
				const numVal = parseFloat(value?.toString() ?? '');
				let nextVal = null;
				if (!Number.isNaN(numVal)) {
					const kgValue = this._weightType == 'kg' ? numVal : this.convertLbsToKg(numVal);
					nextVal = kgValue;
				}
				this.formCtrl.setValue(nextVal);
				this.otherValueDisplay.set(nextVal ? nextVal.toString() : null);
			});

		// Syncing out internal visible control with the user's formControl
		// The user's form control will always be in kg

		const visibleStartValue =
			this._weightType == 'kg' ? this.formCtrl.value : this.convertKgToLbs(this.formCtrl.value);

		const places = this.maxDecimalPlaces() ?? 3;
		const roundedString = visibleStartValue.toFixed(places);

		this.visibleControl.setValue(parseFloat(roundedString));
		this.syncEnabledStates();

		// If the formControl ever becomes disabled, we should disable our visible control.
		this.formCtrl.statusChanges.pipe(distinctUntilChanged(), takeUntil(this._destroy)).subscribe(() => {
			this.syncEnabledStates();
		});
	}

	ngOnDestroy(): void {
		this._destroy.complete();
	}
}
