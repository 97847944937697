<mat-form-field floatLabel="always">
	@if (label()) {
		<mat-label>{{ label() }}</mat-label>
	}
	<span [ngClass]="{ 'data-container': true, empty: !value() }">
		<span>
			<textarea style="display: none" matInput [disabled]="true" [readonly]="true"></textarea>
			{{ value() ?? '--' }}
		</span>
	</span>
</mat-form-field>
