/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum QuantityComparatorApiEnum {
    GreaterOrEqual = 0,
    EnteredInError = 1,
    Ad = 2,
    LessThan = 3,
    GreaterThan = 4,
}

export const QuantityComparatorApiEnumDisplayMap : { [key in QuantityComparatorApiEnum] : string } = {
    [QuantityComparatorApiEnum.GreaterOrEqual]: "GreaterOrEqual",
    [QuantityComparatorApiEnum.EnteredInError]: "EnteredInError",
    [QuantityComparatorApiEnum.Ad]: "Ad",
    [QuantityComparatorApiEnum.LessThan]: "LessThan",
    [QuantityComparatorApiEnum.GreaterThan]: "GreaterThan",
}
