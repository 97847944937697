<mat-card>
	<mat-card-header>
		<mat-card-title>
			Primary Assessment
			<mat-card-subtitle> mat-chip-listbox </mat-card-subtitle>
		</mat-card-title>
	</mat-card-header>
	<mat-card-content>
		@for (item of questions; track $index) {
			{{ item.text }}:
			<mat-chip-listbox>
				@for (opt of item.options; track $index) {
					<mat-chip-option>{{ opt.value }}</mat-chip-option>
				}
			</mat-chip-listbox>
		}
	</mat-card-content>
</mat-card>

<mat-card>
	<mat-card-header>
		<mat-card-title>
			Biff Test
			<mat-card-subtitle> mat-chip-set highlighted </mat-card-subtitle>
		</mat-card-title>
	</mat-card-header>
	<mat-card-content>
		<biff
			style="width: 600px; height: 800px"
			[gender]="GenderEnum.Male"
			[clickableBodyAreas]="['Chest', 'Neck', 'Head']"
			[decalData]="decalData()"
			(onClicked)="onBiffClicked($event)"
			(onDecalResize)="onBiffResize($event)" />
	</mat-card-content>
</mat-card>

<mat-card>
	<mat-card-header>
		<mat-card-title>
			Primary Assessment
			<mat-card-subtitle> mat-chip-set highlighted </mat-card-subtitle>
		</mat-card-title>
	</mat-card-header>
	<mat-card-content>
		@for (item of highlightedChipQuestions; track $index) {
			{{ item.text }}:
			<mat-chip-set>
				@for (opt of item.options; track $index) {
					<mat-chip [highlighted]="opt.selected" (click)="opt.selected = !opt.selected">{{ opt.value }}</mat-chip>
				}
			</mat-chip-set>
		}
	</mat-card-content>
</mat-card>

<mat-card>
	<mat-card-header>
		<mat-card-title>
			Primary Assessment
			<mat-card-subtitle> mat-radio-group </mat-card-subtitle>
		</mat-card-title>
	</mat-card-header>
	<mat-card-content>
		@for (item of radioQuestions; track $index) {
			{{ item.text }}:
			<br />
			<mat-radio-group>
				@for (opt of item.options; track $index) {
					<mat-radio-button [value]="opt.value">{{ opt.value }}</mat-radio-button>
				}
			</mat-radio-group>
			<br />
		}
	</mat-card-content>
</mat-card>

<mat-card>
	<mat-card-header>
		<mat-card-title>
			Primary Assessment
			<mat-card-subtitle> mat-checkbox </mat-card-subtitle>
		</mat-card-title>
	</mat-card-header>
	<mat-card-content>
		@for (item of checkboxQuestions; track $index) {
			{{ item.text }}:
			<br />
			@for (opt of item.options; track $index) {
				<mat-checkbox>{{ opt.value }}</mat-checkbox>
			}
			<br />
		}
	</mat-card-content>
</mat-card>

<mat-card>
	<mat-card-header>
		<mat-card-title>
			Primary Assessment
			<mat-card-subtitle> mat-button-toggle-group </mat-card-subtitle>
		</mat-card-title>
	</mat-card-header>
	<mat-card-content>
		@for (item of buttonGroupQuestions; track $index; let last = $last) {
			{{ item.text }}:
			<br />
			<mat-button-toggle-group multiple>
				@for (opt of item.options; track $index) {
					<mat-button-toggle [value]="opt.value">{{ opt.value }}</mat-button-toggle>
				}
			</mat-button-toggle-group>
			<br />
			@if (!last) {
				<br />
			}
		}
	</mat-card-content>
</mat-card>

<mat-card>
	<mat-card-header>
		<mat-card-title>
			Pain Level
			<mat-card-subtitle> mat-slider </mat-card-subtitle>
		</mat-card-title>
	</mat-card-header>
	<mat-card-content>
		<br />
		<label
			>Current Pain Level: <b>{{ currentPainLevel }}</b></label
		>
		<br /><br />
		<mat-slider style="width: 300px" [max]="10" [min]="1" [step]="1" [discrete]="true" [showTickMarks]="true">
			<input matSliderThumb [(ngModel)]="currentPainLevel" />
		</mat-slider>
		<br />
		<label
			>Max Pain Level: <b>{{ maxPainLevel }}</b></label
		>
		<br /><br />
		<mat-slider style="width: 300px" [max]="10" [min]="1" [step]="1" [discrete]="true" [showTickMarks]="true">
			<input matSliderThumb [(ngModel)]="maxPainLevel" />
		</mat-slider>
		<br />
		<label
			>Acceptable Pain Level: <b>{{ acceptablePainLevel }}</b></label
		>
		<br /><br />
		<mat-slider style="width: 300px" [max]="10" [min]="1" [step]="1" [discrete]="true" [showTickMarks]="true">
			<input matSliderThumb [(ngModel)]="acceptablePainLevel" />
		</mat-slider>
	</mat-card-content>
</mat-card>

<mat-card>
	<mat-card-header>
		<mat-card-title>
			Checkboxes
			<mat-form-field style="width: 100px; margin-left: 10px">
				<mat-label>(#cols)</mat-label>
				<input matInput type="number" [formControl]="checkboxColumnCountControl" />
			</mat-form-field>
		</mat-card-title>
	</mat-card-header>
	<mat-card-content>
		<chip-multi-select-typeahead-input-form-field
			label="auto"
			[formCtrl]="formControl"
			[optionsFilterCallBack]="optionsFilter">
		</chip-multi-select-typeahead-input-form-field>
	</mat-card-content>
</mat-card>

<mat-card>
	<mat-card-content>
		<list-builder [formControl]="formControl"></list-builder>
	</mat-card-content>
</mat-card>

<mat-card>
	<mat-card-content>
		<boolean-text-select-input-form-field
			[formCtrl]="lessConfusingFormControl"
			label="Tetnus"></boolean-text-select-input-form-field>

		<boolean-text-select-input-form-field
			[formCtrl]="lessConfusingFormControl"
			label="Option 2"></boolean-text-select-input-form-field>

		<boolean-text-select-input-form-field
			[formCtrl]="lessConfusingFormControl"
			label="Sand"></boolean-text-select-input-form-field>
	</mat-card-content>
</mat-card>

<mat-card>
	<mat-card-header>
		<mat-card-title> Temperature Ctrl </mat-card-title>
	</mat-card-header>
	<mat-card-content>
		<temperature-input-form-field
			fullWidth
			short
			label="fahrenheit ctrl"
			temperatureType="fahrenheit"
			[formCtrl]="tempFormCtrlF"></temperature-input-form-field>
		<temperature-input-form-field
			fullWidth
			short
			label="celsius ctrl"
			temperatureType="celsius"
			[formCtrl]="tempFormCtrlC"></temperature-input-form-field>
	</mat-card-content>
</mat-card>

<mat-card>
	<mat-card-header>
		<mat-card-title> Weight Ctrl </mat-card-title>
	</mat-card-header>
	<mat-card-content>
		<weight-input-form-field
			fullWidth
			short
			label="kilogram ctrl"
			weightType="kg"
			[formCtrl]="weightFormCtrlKg"></weight-input-form-field>
		<weight-input-form-field
			fullWidth
			short
			label="pounds ctrl"
			weightType="lbs"
			[formCtrl]="weightFormCtrlLbs"></weight-input-form-field>
	</mat-card-content>
</mat-card>

<!-- <mat-card>
	<mat-card-header>
		<mat-card-title> Document Generation Test</mat-card-title>
	</mat-card-header>
	<mat-card-content>
		<button mat-button (click)="generateDocument()">Test</button>
	</mat-card-content>
</mat-card>

<mat-card>
	<mat-card-header>
		<mat-card-title>Select Typeahead</mat-card-title>
	</mat-card-header>
	<mat-card-content> </mat-card-content>
</mat-card> -->
