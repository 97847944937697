<mat-dialog-content>
	<p>
		Your session is about to expire and you will automatically be logged out in
		{{ getRoundedSeconds(data?.sessionTimeLeftMS) | timeLeft }}.
	</p>
</mat-dialog-content>
<mat-dialog-actions>
	<button mat-button (click)="refreshSession()" cdkFocusInitial>I'm still here</button>
	<button mat-button (click)="logout()">Logout</button>
</mat-dialog-actions>
