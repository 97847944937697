import { Injectable } from '@angular/core';
import { AuthService } from '../../auth';
import {
	ChiefComplaintRowApiModel,
	EncounterApiModel,
	EncounterLocationApiModel,
	EncounterStatusApiEnum,
	ReadNotesApiModel
} from '../../generated-models';
import { SiteWideDataService } from './site-wide-data-service.service';

import * as signalR from '@microsoft/signalr';

// These must match the names of the C# signal R endpoints exactly
export type SignalRMessageType =
	| 'EncounterRoomUpdatedAsync'
	| 'EncounterNotesUpdatedAsync'
	| 'EncounterChiefComplaintsUpdatedAsync'
	| 'AddOrUpdateEncounterAsync'
	| 'EncounterStatusChangedAsync'
	| 'AddOrUpdateOrderAsync'
	| 'OrderCountsUpdatedAsync';

@Injectable({
	providedIn: 'root'
})
export class SignalRService {
	private connection: signalR.HubConnection;

	constructor(
		private readonly _siteWideDataService: SiteWideDataService,
		readonly _authService: AuthService
	) {
		this.connection = new signalR.HubConnectionBuilder()
			.withUrl(`${_authService.apiBaseEndPoint}/messageHub`)
			.withAutomaticReconnect()
			.build();
	}

	public on(messageType: SignalRMessageType, messageHandler: (...args: any[]) => any) {
		this.connection.on(messageType, messageHandler);
	}

	public off(messageType: SignalRMessageType, messageHandler: (...args: any[]) => any) {
		this.connection.off(messageType, messageHandler);
	}

	public startListening() {
		this.connection.start();

		this.on('EncounterRoomUpdatedAsync', (encounterId: string, room: EncounterLocationApiModel) => {
			this._siteWideDataService.UpdateEncounterRoom(encounterId, room);
		});

		this.on('EncounterNotesUpdatedAsync', (encounterId: string, notes: ReadNotesApiModel[]) => {
			this._siteWideDataService.UpdateEncounterNotes(encounterId, notes);
		});

		this.on(
			'EncounterChiefComplaintsUpdatedAsync',
			(encounterId: string, chiefComplaints: ChiefComplaintRowApiModel[]) => {
				this._siteWideDataService.UpdateEncounterChiefComplaints(encounterId, chiefComplaints);
			}
		);

		this.on('AddOrUpdateEncounterAsync', (encounter: EncounterApiModel) => {
			this._siteWideDataService.AddOrUpdateEncounter(encounter);
		});

		this.on('EncounterStatusChangedAsync', (encounterId: string, status: EncounterStatusApiEnum) => {
			this._siteWideDataService.EncounterStatusChanged(encounterId, status);
		});

		this.on(
			'OrderCountsUpdatedAsync',
			(
				encounterId: string,
				activeAncillaryOrders: number,
				activeLaboratoryOrders: number,
				activePharmacyOrders: number,
				activeRadiologyOrders: number
			) => {
				this._siteWideDataService.EncounterOrdersUpdated(
					encounterId,
					activeAncillaryOrders,
					activeLaboratoryOrders,
					activePharmacyOrders,
					activeRadiologyOrders
				);
			}
		);
	}
}
