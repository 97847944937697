/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum ObservationStatusApiEnum {
    Preliminary = 0,
    EnteredInError = 1,
    Cancelled = 2,
    Final = 3,
    Amended = 4,
    Corrected = 5,
    Registered = 6,
    Unknown = 7,
}

export const ObservationStatusApiEnumDisplayMap : { [key in ObservationStatusApiEnum] : string } = {
    [ObservationStatusApiEnum.Preliminary]: "Preliminary",
    [ObservationStatusApiEnum.EnteredInError]: "EnteredInError",
    [ObservationStatusApiEnum.Cancelled]: "Cancelled",
    [ObservationStatusApiEnum.Final]: "Final",
    [ObservationStatusApiEnum.Amended]: "Amended",
    [ObservationStatusApiEnum.Corrected]: "Corrected",
    [ObservationStatusApiEnum.Registered]: "Registered",
    [ObservationStatusApiEnum.Unknown]: "Unknown",
}
