/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum QuestionnaireTitleApiEnum {
    Addendum = 0,
    BloodDrawDetails = 1,
    CtInterpretationNote = 2,
    CadiacMonitorDetails = 3,
    CardiacRhythmStripNote = 4,
    CardiovascularAssessment = 5,
    CathFoleyDetails = 6,
    ConsideredButNotDoneNote = 7,
    CriticalCareNote = 8,
    DomesticViolenceScreening = 9,
    EkgDetails = 10,
    EkgFindingsAndImpressions = 11,
    EkgInterpretationNote = 12,
    EscalationOfCareNote = 13,
    FallScreening = 14,
    FamilyMemberHealthHistory = 15,
    FamilyNote = 16,
    FreeTextNote = 17,
    FunctionalNutritionalScreening = 18,
    GastrointestinalAssessment = 19,
    GeneralAssessment = 20,
    GeneralHistory = 21,
    GenitourinaryAssessment = 22,
    HistoryOfPresentIllnessAbdominal = 23,
    HistoryOfPresentIllnessAllergies = 24,
    HistoryOfPresentIllnessAlteredMentalState = 25,
    HistoryOfPresentIllnessAnimalBite = 26,
    HistoryOfPresentIllnessAssault = 27,
    HistoryOfPresentIllnessAsthma = 28,
    HistoryOfPresentIllnessBurnInhalation = 29,
    HistoryOfPresentIllnessCardiopulmonary = 30,
    HistoryOfPresentIllnessChest = 31,
    HistoryOfPresentIllnessCriticalCare = 32,
    HistoryOfPresentIllnessDizziness = 33,
    HistoryOfPresentIllnessDyspnea = 34,
    HistoryOfPresentIllnessEar = 35,
    HistoryOfPresentIllnessEye = 36,
    HistoryOfPresentIllnessFaceScalp = 37,
    HistoryOfPresentIllnessFall = 38,
    HistoryOfPresentIllnessFemaleGu = 39,
    HistoryOfPresentIllnessFlu = 40,
    HistoryOfPresentIllnessFoot = 41,
    HistoryOfPresentIllnessGiBleeding = 42,
    HistoryOfPresentIllnessHand = 43,
    HistoryOfPresentIllnessHead = 44,
    HistoryOfPresentIllnessHeadache = 45,
    HistoryOfPresentIllnessHip = 46,
    HistoryOfPresentIllnessLowerBack = 47,
    HistoryOfPresentIllnessLowerExtremityInjury = 48,
    HistoryOfPresentIllnessLowerExtremityProblem = 49,
    HistoryOfPresentIllnessMvc = 50,
    HistoryOfPresentIllnessMaleGu = 51,
    HistoryOfPresentIllnessMiscellaneous = 52,
    HistoryOfPresentIllnessMouth = 53,
    HistoryOfPresentIllnessMultipleTrauma = 54,
    HistoryOfPresentIllnessNvd = 55,
    HistoryOfPresentIllnessNeckUpperBack = 56,
    HistoryOfPresentIllnessNeuro = 57,
    HistoryOfPresentIllnessNose = 58,
    HistoryOfPresentIllnessPalpitations = 59,
    HistoryOfPresentIllnessPediatricAbdominal = 60,
    HistoryOfPresentIllnessPediatricIllness = 61,
    HistoryOfPresentIllnessPediatricInjury = 62,
    HistoryOfPresentIllnessPediatricWheezing = 63,
    HistoryOfPresentIllnessPlantar = 64,
    HistoryOfPresentIllnessPregnancy = 65,
    HistoryOfPresentIllnessPsychSuicideOverdose = 66,
    HistoryOfPresentIllnessSeizure = 67,
    HistoryOfPresentIllnessShoulder = 68,
    HistoryOfPresentIllnessSkin = 69,
    HistoryOfPresentIllnessSyncope = 70,
    HistoryOfPresentIllnessTrunk = 71,
    HistoryOfPresentIllnessUpperExtemityInjury = 72,
    HistoryOfPresentIllnessUpperExtemityProblem = 73,
    IvDetails = 74,
    InfectiousDiseaseScreening = 75,
    IntegumentaryAssessment = 76,
    MentalHealthScreening = 77,
    MusculoskeletalAssessment = 78,
    NeurologicalAssessment = 79,
    NgTubeDetails = 80,
    NurseTriage = 81,
    ObsNotesDischarge = 82,
    ObsNotesProgress = 83,
    OutsideRecordsNote = 84,
    PnpReview = 85,
    PastMedicalHistory = 86,
    PastSurgicalHistory = 87,
    PatientDischargeQuestionnaire = 88,
    PediatricMentalHealthScreening = 89,
    PhysicianProgressNote = 90,
    ProcedureDetails = 91,
    ProcedureNote = 92,
    ProgressReAssessmentNote = 93,
    RadiographicInterpretationUltrasound = 94,
    RadiologyInterpretation = 95,
    ReAssessment = 96,
    ReferralNoteDocToDoc = 97,
    RespiratoryAssessment = 98,
    SepsisScreening = 99,
    SocialHistory = 100,
    SplintPlacementDetails = 101,
    UaCollectionDetails = 102,
    VitalSigns = 103,
    XRayInterpretationNote = 104,
}

export const QuestionnaireTitleApiEnumDisplayMap : { [key in QuestionnaireTitleApiEnum] : string } = {
    [QuestionnaireTitleApiEnum.Addendum]: "Addendum",
    [QuestionnaireTitleApiEnum.BloodDrawDetails]: "BloodDrawDetails",
    [QuestionnaireTitleApiEnum.CtInterpretationNote]: "CtInterpretationNote",
    [QuestionnaireTitleApiEnum.CadiacMonitorDetails]: "CadiacMonitorDetails",
    [QuestionnaireTitleApiEnum.CardiacRhythmStripNote]: "CardiacRhythmStripNote",
    [QuestionnaireTitleApiEnum.CardiovascularAssessment]: "CardiovascularAssessment",
    [QuestionnaireTitleApiEnum.CathFoleyDetails]: "CathFoleyDetails",
    [QuestionnaireTitleApiEnum.ConsideredButNotDoneNote]: "ConsideredButNotDoneNote",
    [QuestionnaireTitleApiEnum.CriticalCareNote]: "CriticalCareNote",
    [QuestionnaireTitleApiEnum.DomesticViolenceScreening]: "DomesticViolenceScreening",
    [QuestionnaireTitleApiEnum.EkgDetails]: "EkgDetails",
    [QuestionnaireTitleApiEnum.EkgFindingsAndImpressions]: "EkgFindingsAndImpressions",
    [QuestionnaireTitleApiEnum.EkgInterpretationNote]: "EkgInterpretationNote",
    [QuestionnaireTitleApiEnum.EscalationOfCareNote]: "EscalationOfCareNote",
    [QuestionnaireTitleApiEnum.FallScreening]: "FallScreening",
    [QuestionnaireTitleApiEnum.FamilyMemberHealthHistory]: "FamilyMemberHealthHistory",
    [QuestionnaireTitleApiEnum.FamilyNote]: "FamilyNote",
    [QuestionnaireTitleApiEnum.FreeTextNote]: "FreeTextNote",
    [QuestionnaireTitleApiEnum.FunctionalNutritionalScreening]: "FunctionalNutritionalScreening",
    [QuestionnaireTitleApiEnum.GastrointestinalAssessment]: "GastrointestinalAssessment",
    [QuestionnaireTitleApiEnum.GeneralAssessment]: "GeneralAssessment",
    [QuestionnaireTitleApiEnum.GeneralHistory]: "GeneralHistory",
    [QuestionnaireTitleApiEnum.GenitourinaryAssessment]: "GenitourinaryAssessment",
    [QuestionnaireTitleApiEnum.HistoryOfPresentIllnessAbdominal]: "HistoryOfPresentIllnessAbdominal",
    [QuestionnaireTitleApiEnum.HistoryOfPresentIllnessAllergies]: "HistoryOfPresentIllnessAllergies",
    [QuestionnaireTitleApiEnum.HistoryOfPresentIllnessAlteredMentalState]: "HistoryOfPresentIllnessAlteredMentalState",
    [QuestionnaireTitleApiEnum.HistoryOfPresentIllnessAnimalBite]: "HistoryOfPresentIllnessAnimalBite",
    [QuestionnaireTitleApiEnum.HistoryOfPresentIllnessAssault]: "HistoryOfPresentIllnessAssault",
    [QuestionnaireTitleApiEnum.HistoryOfPresentIllnessAsthma]: "HistoryOfPresentIllnessAsthma",
    [QuestionnaireTitleApiEnum.HistoryOfPresentIllnessBurnInhalation]: "HistoryOfPresentIllnessBurnInhalation",
    [QuestionnaireTitleApiEnum.HistoryOfPresentIllnessCardiopulmonary]: "HistoryOfPresentIllnessCardiopulmonary",
    [QuestionnaireTitleApiEnum.HistoryOfPresentIllnessChest]: "HistoryOfPresentIllnessChest",
    [QuestionnaireTitleApiEnum.HistoryOfPresentIllnessCriticalCare]: "HistoryOfPresentIllnessCriticalCare",
    [QuestionnaireTitleApiEnum.HistoryOfPresentIllnessDizziness]: "HistoryOfPresentIllnessDizziness",
    [QuestionnaireTitleApiEnum.HistoryOfPresentIllnessDyspnea]: "HistoryOfPresentIllnessDyspnea",
    [QuestionnaireTitleApiEnum.HistoryOfPresentIllnessEar]: "HistoryOfPresentIllnessEar",
    [QuestionnaireTitleApiEnum.HistoryOfPresentIllnessEye]: "HistoryOfPresentIllnessEye",
    [QuestionnaireTitleApiEnum.HistoryOfPresentIllnessFaceScalp]: "HistoryOfPresentIllnessFaceScalp",
    [QuestionnaireTitleApiEnum.HistoryOfPresentIllnessFall]: "HistoryOfPresentIllnessFall",
    [QuestionnaireTitleApiEnum.HistoryOfPresentIllnessFemaleGu]: "HistoryOfPresentIllnessFemaleGu",
    [QuestionnaireTitleApiEnum.HistoryOfPresentIllnessFlu]: "HistoryOfPresentIllnessFlu",
    [QuestionnaireTitleApiEnum.HistoryOfPresentIllnessFoot]: "HistoryOfPresentIllnessFoot",
    [QuestionnaireTitleApiEnum.HistoryOfPresentIllnessGiBleeding]: "HistoryOfPresentIllnessGiBleeding",
    [QuestionnaireTitleApiEnum.HistoryOfPresentIllnessHand]: "HistoryOfPresentIllnessHand",
    [QuestionnaireTitleApiEnum.HistoryOfPresentIllnessHead]: "HistoryOfPresentIllnessHead",
    [QuestionnaireTitleApiEnum.HistoryOfPresentIllnessHeadache]: "HistoryOfPresentIllnessHeadache",
    [QuestionnaireTitleApiEnum.HistoryOfPresentIllnessHip]: "HistoryOfPresentIllnessHip",
    [QuestionnaireTitleApiEnum.HistoryOfPresentIllnessLowerBack]: "HistoryOfPresentIllnessLowerBack",
    [QuestionnaireTitleApiEnum.HistoryOfPresentIllnessLowerExtremityInjury]: "HistoryOfPresentIllnessLowerExtremityInjury",
    [QuestionnaireTitleApiEnum.HistoryOfPresentIllnessLowerExtremityProblem]: "HistoryOfPresentIllnessLowerExtremityProblem",
    [QuestionnaireTitleApiEnum.HistoryOfPresentIllnessMvc]: "HistoryOfPresentIllnessMvc",
    [QuestionnaireTitleApiEnum.HistoryOfPresentIllnessMaleGu]: "HistoryOfPresentIllnessMaleGu",
    [QuestionnaireTitleApiEnum.HistoryOfPresentIllnessMiscellaneous]: "HistoryOfPresentIllnessMiscellaneous",
    [QuestionnaireTitleApiEnum.HistoryOfPresentIllnessMouth]: "HistoryOfPresentIllnessMouth",
    [QuestionnaireTitleApiEnum.HistoryOfPresentIllnessMultipleTrauma]: "HistoryOfPresentIllnessMultipleTrauma",
    [QuestionnaireTitleApiEnum.HistoryOfPresentIllnessNvd]: "HistoryOfPresentIllnessNvd",
    [QuestionnaireTitleApiEnum.HistoryOfPresentIllnessNeckUpperBack]: "HistoryOfPresentIllnessNeckUpperBack",
    [QuestionnaireTitleApiEnum.HistoryOfPresentIllnessNeuro]: "HistoryOfPresentIllnessNeuro",
    [QuestionnaireTitleApiEnum.HistoryOfPresentIllnessNose]: "HistoryOfPresentIllnessNose",
    [QuestionnaireTitleApiEnum.HistoryOfPresentIllnessPalpitations]: "HistoryOfPresentIllnessPalpitations",
    [QuestionnaireTitleApiEnum.HistoryOfPresentIllnessPediatricAbdominal]: "HistoryOfPresentIllnessPediatricAbdominal",
    [QuestionnaireTitleApiEnum.HistoryOfPresentIllnessPediatricIllness]: "HistoryOfPresentIllnessPediatricIllness",
    [QuestionnaireTitleApiEnum.HistoryOfPresentIllnessPediatricInjury]: "HistoryOfPresentIllnessPediatricInjury",
    [QuestionnaireTitleApiEnum.HistoryOfPresentIllnessPediatricWheezing]: "HistoryOfPresentIllnessPediatricWheezing",
    [QuestionnaireTitleApiEnum.HistoryOfPresentIllnessPlantar]: "HistoryOfPresentIllnessPlantar",
    [QuestionnaireTitleApiEnum.HistoryOfPresentIllnessPregnancy]: "HistoryOfPresentIllnessPregnancy",
    [QuestionnaireTitleApiEnum.HistoryOfPresentIllnessPsychSuicideOverdose]: "HistoryOfPresentIllnessPsychSuicideOverdose",
    [QuestionnaireTitleApiEnum.HistoryOfPresentIllnessSeizure]: "HistoryOfPresentIllnessSeizure",
    [QuestionnaireTitleApiEnum.HistoryOfPresentIllnessShoulder]: "HistoryOfPresentIllnessShoulder",
    [QuestionnaireTitleApiEnum.HistoryOfPresentIllnessSkin]: "HistoryOfPresentIllnessSkin",
    [QuestionnaireTitleApiEnum.HistoryOfPresentIllnessSyncope]: "HistoryOfPresentIllnessSyncope",
    [QuestionnaireTitleApiEnum.HistoryOfPresentIllnessTrunk]: "HistoryOfPresentIllnessTrunk",
    [QuestionnaireTitleApiEnum.HistoryOfPresentIllnessUpperExtemityInjury]: "HistoryOfPresentIllnessUpperExtemityInjury",
    [QuestionnaireTitleApiEnum.HistoryOfPresentIllnessUpperExtemityProblem]: "HistoryOfPresentIllnessUpperExtemityProblem",
    [QuestionnaireTitleApiEnum.IvDetails]: "IvDetails",
    [QuestionnaireTitleApiEnum.InfectiousDiseaseScreening]: "InfectiousDiseaseScreening",
    [QuestionnaireTitleApiEnum.IntegumentaryAssessment]: "IntegumentaryAssessment",
    [QuestionnaireTitleApiEnum.MentalHealthScreening]: "MentalHealthScreening",
    [QuestionnaireTitleApiEnum.MusculoskeletalAssessment]: "MusculoskeletalAssessment",
    [QuestionnaireTitleApiEnum.NeurologicalAssessment]: "NeurologicalAssessment",
    [QuestionnaireTitleApiEnum.NgTubeDetails]: "NgTubeDetails",
    [QuestionnaireTitleApiEnum.NurseTriage]: "NurseTriage",
    [QuestionnaireTitleApiEnum.ObsNotesDischarge]: "ObsNotesDischarge",
    [QuestionnaireTitleApiEnum.ObsNotesProgress]: "ObsNotesProgress",
    [QuestionnaireTitleApiEnum.OutsideRecordsNote]: "OutsideRecordsNote",
    [QuestionnaireTitleApiEnum.PnpReview]: "PnpReview",
    [QuestionnaireTitleApiEnum.PastMedicalHistory]: "PastMedicalHistory",
    [QuestionnaireTitleApiEnum.PastSurgicalHistory]: "PastSurgicalHistory",
    [QuestionnaireTitleApiEnum.PatientDischargeQuestionnaire]: "PatientDischargeQuestionnaire",
    [QuestionnaireTitleApiEnum.PediatricMentalHealthScreening]: "PediatricMentalHealthScreening",
    [QuestionnaireTitleApiEnum.PhysicianProgressNote]: "PhysicianProgressNote",
    [QuestionnaireTitleApiEnum.ProcedureDetails]: "ProcedureDetails",
    [QuestionnaireTitleApiEnum.ProcedureNote]: "ProcedureNote",
    [QuestionnaireTitleApiEnum.ProgressReAssessmentNote]: "ProgressReAssessmentNote",
    [QuestionnaireTitleApiEnum.RadiographicInterpretationUltrasound]: "RadiographicInterpretationUltrasound",
    [QuestionnaireTitleApiEnum.RadiologyInterpretation]: "RadiologyInterpretation",
    [QuestionnaireTitleApiEnum.ReAssessment]: "ReAssessment",
    [QuestionnaireTitleApiEnum.ReferralNoteDocToDoc]: "ReferralNoteDocToDoc",
    [QuestionnaireTitleApiEnum.RespiratoryAssessment]: "RespiratoryAssessment",
    [QuestionnaireTitleApiEnum.SepsisScreening]: "SepsisScreening",
    [QuestionnaireTitleApiEnum.SocialHistory]: "SocialHistory",
    [QuestionnaireTitleApiEnum.SplintPlacementDetails]: "SplintPlacementDetails",
    [QuestionnaireTitleApiEnum.UaCollectionDetails]: "UaCollectionDetails",
    [QuestionnaireTitleApiEnum.VitalSigns]: "VitalSigns",
    [QuestionnaireTitleApiEnum.XRayInterpretationNote]: "XRayInterpretationNote",
}
