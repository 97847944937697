import { CommonModule } from '@angular/common';
import { Component, computed, effect, input, OnInit, output, signal, WritableSignal } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import {
	CodingApiModel,
	CustomInputControlTypeApiEnum,
	QuestionnaireItemApiModel,
	QuestionnaireItemTypeApiEnum
} from '../../../../generated-models';
import { SiteWideDataService } from '../../../services/site-wide-data-service.service';
import { BodyMeasurementComponent } from '../../input-form-fields/body-measurement-component/body-measurement-component.component';
import { QuestionnaireItemFormFieldComponent } from '../questionnaire-item/questionnaire-item.component';
import {
	QuestionnaireGroupChangedEvent,
	QuestionnaireItemChangedEvent,
	QuestionnaireService
} from '../questionnaire.service';

import cloneDeep from 'lodash-es/cloneDeep';
import groupBy from 'lodash-es/groupBy';
import uniqueId from 'lodash-es/uniqueId';

class GroupUIHelper {
	key: string = uniqueId();
	childItemVisibilty: WritableSignal<Record<string, boolean>> = signal({});
	group: QuestionnaireItemApiModel;
	hidden: WritableSignal<boolean>;

	constructor(group: QuestionnaireItemApiModel, hidden: boolean) {
		this.group = group;
		this.hidden = signal(hidden);
	}
}

interface LinkIdToQuestionnaireItemApiModel {
	linkId: string;
	items: QuestionnaireItemApiModel[];
}

@Component({
	selector: 'questionnaire-group',
	standalone: true,
	imports: [
		QuestionnaireItemFormFieldComponent,
		BodyMeasurementComponent,
		MatButtonModule,
		MatIconModule,
		CommonModule
	],
	templateUrl: './questionnaire-group.component.html',
	styleUrl: './questionnaire-group.component.scss'
})
export class QuestionnaireFormGroupComponent implements OnInit {
	public readonly = input<boolean>(false);
	public groups = input.required<QuestionnaireItemApiModel[]>();
	public visible = input<boolean>(true);
	public explicitlyHiddenCodes = input<CodingApiModel[] | undefined>(undefined);

	// for group multi handling, we actually have an array of Groups based off the original
	public displayGroups = signal<GroupUIHelper[]>([]);

	public templateSections = computed<(string | number)[]>(() => {
		const groups = this.groups();
		if (groups.length == 0 || !groups[0].groupTemplate) {
			return [];
		}

		const inputString = groups[0].groupTemplate;
		const splitParts = inputString.split(/%\d+/);
		const matches = inputString.match(/%\d+/g)?.map(match => parseInt(match.slice(1), 10)) || [];

		const result: (string | number)[] = [];
		splitParts.forEach((part, index) => {
			result.push(part);
			if (index < matches.length) {
				result.push(matches[index]);
			}
		});
		return result;
	});

	public groupLinkId = computed<string>(() => {
		const groups = this.groups();
		return groups.length ? groups[0].linkId : '';
	});

	public allowAddingOrRemoving = true;

	public isMulti = computed<boolean>(() => {
		return this.groups().some(x => x.repeats);
	});

	public shouldRender = computed(() => {
		const encounter = this._siteWideDataService.selectedEncounter();
		const groups = this.groups();

		if (groups.length == 0) {
			return false;
		}
		const item = groups[0];
		return this._questionnaireService.shouldItemRender(item, encounter);
	});

	public hidableItemsByGroup = computed<QuestionnaireItemApiModel[][]>(() => {
		const displayGroupIdentifiers = this.displayGroups();
		return displayGroupIdentifiers.map(identifier => {
			return identifier.group.item?.filter(item => item.enableWhen && item.enableWhen.length > 0) ?? [];
		});
	});

	public ItemType = QuestionnaireItemTypeApiEnum;
	public CustomControlType = CustomInputControlTypeApiEnum;

	public isString(item: unknown) {
		return typeof item === 'string';
	}
	// Will output the inner controls:
	// So the group can be repeating
	//
	// {
	//   groupLinkId: [
	//    { [childItem1_linkId]: value, [childItem2_linkId]: value },
	//    { [childItem1_linkId]: value, [childItem2_linkId]: value }
	//   ]
	// }
	public onChangeValue = output<QuestionnaireGroupChangedEvent>();

	public groupState = signal<QuestionnaireGroupChangedEvent>({});

	public explicitlyHiddenCodeSet = computed<Set<string>>(() => {
		const hiddenCodes = this.explicitlyHiddenCodes();
		return hiddenCodes ? new Set<string>(hiddenCodes.map(x => x.code ?? '')) : new Set<string>();
	});

	constructor(
		private _siteWideDataService: SiteWideDataService,
		private _questionnaireService: QuestionnaireService
	) {
		effect(() => {
			const state = this.groupState();
			if (state) {
				this.onChangeValue.emit(state);
			}
		});
	}

	isExplicitlyHidden(item: QuestionnaireItemApiModel) {
		if (item.code && item.code.length) {
			const hiddenCodes = this.explicitlyHiddenCodeSet();
			return !!item.code.find(x => x.code && hiddenCodes.has(x.code));
		}
		return false;
	}

	getLegendHeader(text: string, index: number) {
		return this.isMulti() ? `${text} - ${index + 1}` : text;
	}

	groupItemsByLinkId(items: QuestionnaireItemApiModel[] | undefined): LinkIdToQuestionnaireItemApiModel[] {
		if (!items || !items.length) {
			return [];
		}
		const groupedItems = groupBy(items, item => item.linkId);
		return Object.entries(groupedItems).map(([key, value]) => ({ linkId: key, items: value }));
	}

	createBlankGroup(): GroupUIHelper | null {
		const groups = this.groups();
		if (!groups?.length) {
			return null;
		}

		const cloneMe = groups[0];
		const clone = cloneDeep(cloneMe);

		// We don't want the new group to have an initials value
		const clearInitialValues = (item: QuestionnaireItemApiModel) => {
			item.initial = [];
			item.item?.map(clearInitialValues);
		};
		clearInitialValues(clone);

		return new GroupUIHelper(clone, true);
	}

	addNewGroup() {
		if (this.isMulti() && this.allowAddingOrRemoving) {
			this.allowAddingOrRemoving = false;
			const newGroup = this.createBlankGroup();
			if (newGroup) {
				this.displayGroups.update(old => [...old, newGroup]);

				setTimeout(() => {
					newGroup.hidden.set(false);
					this.allowAddingOrRemoving = true;
				}, 0);
			} else {
				this.allowAddingOrRemoving = true;
			}
		}
	}

	removeGroup(group: GroupUIHelper) {
		if (this.allowAddingOrRemoving == true) {
			this.allowAddingOrRemoving = false;
			const groupIndex = this.displayGroups().findIndex(x => x.key == group.key);
			const isMulti = this.isMulti();
			if (isMulti) {
				group.hidden.set(true);
				setTimeout(() => {
					this.displayGroups.update(old => {
						return old.filter(item => item.key != group.key);
					});

					// Remove it out of the state
					const linkId = this.groupLinkId();
					this.groupState.update(old => {
						old[linkId].splice(groupIndex, 1);
						const nextState = { ...old };
						return nextState;
					});
					this.allowAddingOrRemoving = true;
				}, 500);
			} else {
				this.allowAddingOrRemoving = true;
			}
		}
	}

	trackByLinkId(item: QuestionnaireItemApiModel): string {
		return item.linkId;
	}

	onChildItemChanged(
		index: number,
		groupItem: GroupUIHelper,
		event: QuestionnaireItemChangedEvent | QuestionnaireGroupChangedEvent
	) {
		const groupId = this.groupLinkId();
		const old = this.groupState();
		const fullGroupState = { ...old };

		const eventKeys: string[] = [];
		for (const key in event) {
			const castState = fullGroupState as Record<string, Record<string, unknown>[]>;
			if (!castState[groupId]) {
				castState[groupId] = [{}];
			}
			if (!castState[groupId][index]) {
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				(castState[groupId] as any)[index] = {};
			}
			castState[groupId][index][key] = event[key];
			eventKeys.push(key);
		}
		this.groupState.set(fullGroupState);

		const hidabledGroups = this.hidableItemsByGroup();
		const affectedItems = hidabledGroups[index].filter(item =>
			item.enableWhen?.some(logicItem => eventKeys.includes(logicItem.question as string))
		);

		// this is because we need to get the sub group in the case of "repeats"
		// We need to know which group to update
		const updatedItemState = fullGroupState[groupId][index];

		const visibilityChanges = this._questionnaireService.getQuestionnaireVisibilityRows(
			affectedItems,
			groupItem.group.item ?? [],
			updatedItemState
		);

		this.displayGroups.update(old => {
			const next = [...old];
			const relevantGroup = next.find(x => x.key == groupItem.key);
			if (relevantGroup) {
				const visMap = relevantGroup.childItemVisibilty();
				visibilityChanges.forEach(visiblityRow => {
					visMap[visiblityRow.linkId] = visiblityRow.visible;
				});
			}
			return next;
		});
	}

	ngOnInit(): void {
		const displayGroups = this.groups()
			.filter(x => x.dirty || !this.isMulti())
			.map(group => new GroupUIHelper(group, false));
		this.displayGroups.set(displayGroups);
	}
}
