import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
	CodeableConceptAdminBaseDataApiModel,
	CodeableConceptSearchParametersApiModel,
	CodeableConceptSearchResultApiModel
} from '../../generated-models';
import { AppLoaderService, NotificationService } from '../../layout';
import { BaseService } from './base-service.service';

@Injectable({
	providedIn: 'root'
})
export class CodeableConceptService extends BaseService<CodeableConceptAdminBaseDataApiModel> {
	constructor(
		_httpClient: HttpClient,
		_notificationService: NotificationService,
		private _appLoaderService: AppLoaderService
	) {
		super('codeable-concept', _httpClient, _notificationService);
	}

	public searchCodeableConcepts(
		searchParameters: CodeableConceptSearchParametersApiModel
	): Observable<HttpResponse<CodeableConceptSearchResultApiModel>> {
		return this.post('search', 'Failed to search the codeable concepts.', searchParameters);
	}
}
