@if (shouldRender()) {
	@for (displayGroup of displayGroups(); track displayGroup.key; let group_index = $index) {
		<div
			class="ctrl-and-label-wrapper"
			[ngClass]="{
				hidden: !visible() || displayGroup.hidden()
			}">
			@if (!displayGroup.hidden() && !readonly() && isMulti()) {
				<button
					class="delete-button"
					aria-label="Remove item"
					mat-icon-button
					(click)="removeGroup(displayGroup)">
					<mat-icon color="warn">close</mat-icon>
				</button>
			}
			<div class="animation-wrapper">
				@if (displayGroup.group.customInputType == CustomControlType.BodyMeasurement) {
					<body-measurement-component
						[group]="displayGroup.group"
						[readonly]="readonly()"
						(onChangeValue)="onChangeValue.emit($event)" />
				} @else if (displayGroup.group.groupTemplate) {
					@let items = displayGroup.group.item ?? [];
					@for (section of templateSections(); track section) {
						@if (isString(section)) {
							<span class="template-text">{{ section }}</span>
						} @else {
							@let child = items[$any(section)];
							<span class="template-ctrl-wrapper">
								<questionnaire-item
									[item]="child"
									[readonly]="readonly()"
									(onChangeValue)="onChildItemChanged(group_index, displayGroup, $event)" />
							</span>
						}
					}
				} @else if (displayGroup.group.text) {
					<fieldset>
						<legend>
							{{ getLegendHeader(displayGroup.group.text, $index) }}
						</legend>
						@let groupedChildren = groupItemsByLinkId(displayGroup.group.item);
						@for (childGroup of groupedChildren; track childGroup.linkId) {
							@let childIsVisible = displayGroup.childItemVisibilty()[childGroup.linkId] !== false;
							@let firstChild = childGroup.items[0];
							@let hiddenBasedOnExplicitCode = isExplicitlyHidden(firstChild);
							@let visible = !hiddenBasedOnExplicitCode && childIsVisible;
							@if (firstChild.dataType == ItemType.Group) {
								<questionnaire-group
									[groups]="childGroup.items"
									[visible]="visible"
									[readonly]="readonly()"
									[explicitlyHiddenCodes]="explicitlyHiddenCodes()"
									(onChangeValue)="onChildItemChanged(group_index, displayGroup, $event)" />
							} @else {
								<questionnaire-item
									[item]="firstChild"
									[visible]="visible"
									[readonly]="readonly()"
									(onChangeValue)="onChildItemChanged(group_index, displayGroup, $event)" />
							}
						}
					</fieldset>
				} @else {
					@let groupedChildren = groupItemsByLinkId(displayGroup.group.item);
					@for (childGroup of groupedChildren; track childGroup.linkId) {
						@let childIsVisible = displayGroup.childItemVisibilty()[childGroup.linkId] !== false;
						@let firstChild = childGroup.items[0];
						@if (firstChild.dataType == ItemType.Group) {
							<questionnaire-group
								[groups]="childGroup.items"
								[visible]="childIsVisible"
								[readonly]="readonly()"
								(onChangeValue)="onChildItemChanged(group_index, displayGroup, $event)" />
						} @else {
							<questionnaire-item
								[item]="firstChild"
								[readonly]="readonly()"
								[visible]="childIsVisible"
								(onChangeValue)="onChildItemChanged(group_index, displayGroup, $event)" />
						}
					}
				}
			</div>
		</div>
	}

	@if (!readonly() && isMulti() && visible() && groups() && groups().length) {
		<button class="add-button" mat-flat-button matTooltip="Add" (click)="addNewGroup()">
			Add {{ groups()[0].text }}<mat-icon aria-label="Add item">add</mat-icon>
		</button>
	}
}
