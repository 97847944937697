<mat-sidenav-container>
	<mat-sidenav
		#sidenav
		mode="over"
		position="end"
		[opened]="appSidenavService.opened$ | async"
		(closed)="appSidenavService.closeSidenav()">
		<div class="app-sidenav-container">
			<div class="app-sidenav-title-bar-container">
				<div class="app-sidenav-title-container">
					<span class="app-sidenav-title">{{ (appSidenavService.sidenavConfig$ | async)?.title }}</span>
					<div class="app-sidenav-subtitle" *ngIf="(appSidenavService.sidenavConfig$ | async)?.subtitle">
						{{ (appSidenavService.sidenavConfig$ | async)?.subtitle }}
					</div>
				</div>
				<button
					class="app-sidenav-close-btn"
					mat-icon-button
					aria-label="Close"
					(click)="appSidenavService.closeSidenav()"
					[disabled]="appSidenavService.isProcessing$ | async">
					<mat-icon aria-hidden="false">close</mat-icon>
				</button>
			</div>

			<ng-template [cdkPortalOutlet]="sidenavContentPortal"></ng-template>
		</div>
	</mat-sidenav>
	<mat-sidenav-content>
		<ng-content></ng-content>
	</mat-sidenav-content>
</mat-sidenav-container>
