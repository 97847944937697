<action-card
	[ngClass]="{ 'fill-vertical-space': fillVerticalSpace() }"
	class="parent-card"
	[title]="titleOverride() ?? questionnaire()?.title ?? ''"
	noFooterContent
	[headerOptions]="headerOptions()"
	(headerButtonClicked)="onCornerButtonPressed.emit()">
	@if (questionnaire()) {
		<div class="content-wrapper" [ngClass]="{ hidden: hiddenState() }">
			<div
				class="question-items-wrapper"
				[ngClass]="{ 'auto-col': displayColumns() === undefined }"
				[ngStyle]="displayColumns() !== undefined ? { 'column-count': displayColumns() } : {}">
				@if (showMetaInfo()) {
					<div class="meta-info-wrapper">
						<readonly-display-input label="Last updated" [value]="lastUpdatedTime()" />
						<readonly-display-input label="Updated By" [value]="questionnaire()?.responseInfo?.updatedBy" />
					</div>
				}
				@for (item of linkedQuestionnaireItems(); track JSON.stringify(item.items); let y = $index) {
					@let visibleBasedOnInputValue = visibleItems()[item.linkId];
					@let firstChild = item.items[0];
					@let hiddenBasedOnExplicitCode = isExplicitlyHidden(firstChild);
					@let visible =
						!hiddenBasedOnExplicitCode &&
						(visibleBasedOnInputValue == undefined || visibleBasedOnInputValue) &&
						hiddenState() != true;
					@if (firstChild.dataType == ItemType.Group) {
						<questionnaire-group
							[ngStyle]="item.items[0].groupTemplate ? { 'column-span': 'all', display: 'flex' } : {}"
							[groups]="item.items"
							[readonly]="readonly()"
							[visible]="visible"
							[explicitlyHiddenCodes]="explicitlyHiddenCodes()"
							(onChangeValue)="onItemChanged($event)" />
					} @else {
						<questionnaire-item
							[item]="firstChild"
							[readonly]="readonly()"
							[visible]="visible"
							(onChangeValue)="onItemChanged($event)" />
					}
				}
			</div>
		</div>
	}
</action-card>
