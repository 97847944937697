@if (shouldRender()) {
	<div
		class="ctrl-and-label-wrapper"
		[ngClass]="{
			hidden: !visible()
		}">
		<div class="animation-wrapper">
			@if (externalLabel()) {
				<label>
					@for (line of externalLabel(); track line) {
						@if (line || (!$first && !$last)) {
							@if (line.startsWith('- ')) {
								<ul
									[ngStyle]="{
										'margin-top': $first ? '4px' : '-4px',
										'margin-bottom': $last ? '0px' : '-4px',
										'padding-left': '20px'
									}">
									<li>{{ line.substring(2) }}</li>
								</ul>
							} @else {
								{{ line }}
							}
							@if (!$last) {
								<br />
							}
						}
					}
				</label>
			}
			@if (formCtrl()) {
				<div>
					@let ctrlType = item().customInputType;
					@if (ctrlType == CustomInputControlType.PrimaryChiefComplaint) {
						<readonly-display-input [label]="internalLabel()" [value]="chiefComplaintDisplayValue()" />
					} @else if (ctrlType == CustomInputControlType.Temperature) {
						<temperature-input-form-field
							short
							fullWidth
							temperatureType="fahrenheit"
							[maxDecimalPlaces]="item().maxDecimalPlaces"
							[label]="internalLabel()"
							[formCtrl]="formCtrl()"
							[readonly]="readonly()" />
					} @else if (ctrlType == CustomInputControlType.FhirTypeahead) {
						<chip-multi-select-typeahead-input-form-field
							[label]="internalLabel()"
							[formCtrl]="formCtrl()"
							[optionsFilterCallBack]="fhirTerminologyConceptSetOptionsFilter" />
					} @else if (ctrlType == CustomInputControlType.Weight) {
						<weight-input-form-field
							short
							fullWidth
							weightType="lbs"
							[maxDecimalPlaces]="item().maxDecimalPlaces"
							[label]="internalLabel()"
							[formCtrl]="formCtrl()"
							[readonly]="readonly()" />
					} @else if (ctrlType == CustomInputControlType.Slider) {
						@if (readonly()) {
							@let displayVal =
								formCtrl().value ? formCtrl().value.toString() + ' out of ' + item().maxValue : undefined;
							<readonly-display-input [label]="internalLabel()" [value]="displayVal" />
						} @else {
							<div class="slider-label-container">
								<label>{{ internalLabel() }}</label>
								<span>{{ formCtrl().value ?? '*' }}</span>
							</div>
							<div class="slider-container" [style]="readonly() ? 'pointer-events: none' : ''">
								<mat-slider
									[max]="item().maxValue"
									[min]="item().minValue"
									[step]="1"
									[discrete]="true"
									[showTickMarks]="true">
									<input matSliderThumb [formControl]="formCtrl()" />
								</mat-slider>
							</div>
						}
					} @else if (ctrlType == CustomInputControlType.VerticalRadio) {
						<radio-group-input-form-field
							[options]="options()"
							[formCtrl]="formCtrl()"
							[label]="internalLabel()"
							[vertical]="true" />
					} @else if (useInlineRadioButton()) {
						<radio-group-input-form-field [options]="options()" [formCtrl]="formCtrl()" [label]="internalLabel()" />
					} @else if (item().dataType == QuestionnaireItemType.Quantity) {
						<mat-form-field class="full-width short">
							@if (internalLabel()) {
								<mat-label style="white-space: nowrap">{{ internalLabel() }}</mat-label>
							}
							<input
								matInput
								type="text"
								[formControl]="formCtrl()"
								gatedInput
								[gateFunction]="decimalOnlyGateGenerator(item().maxDecimalPlaces)" />
							<span class="suffix" style="margin-right: 16px" matSuffix>{{ item().suffix }}</span>
						</mat-form-field>
					} @else if (item().dataType == QuestionnaireItemType.Boolean) {
						<checkbox-input-form-field [label]="internalLabel()" [formCtrl]="formCtrl()" [readonly]="readonly()" />
					} @else if (item().dataType == QuestionnaireItemType.Text) {
						<text-area-input-form-field
							fullWidth
							[label]="internalLabel()"
							[formCtrl]="formCtrl()"
							[readonly]="readonly()" />
					} @else if (item().dataType == QuestionnaireItemType.String) {
						<text-input-form-field
							short
							fullWidth
							[suffix]="item().suffix"
							[label]="internalLabel()"
							[formCtrl]="formCtrl()"
							[readonly]="readonly()" />
					} @else if (item().dataType == QuestionnaireItemType.Integer) {
						<text-input-form-field
							short
							fullWidth
							[suffix]="item().suffix"
							[label]="internalLabel()"
							[formCtrl]="formCtrl()"
							[readonly]="readonly()"
							gatedInput
							[gateFunction]="integerOnlyGate" />
					} @else if (item().dataType == QuestionnaireItemType.Choice) {
						@if (item().repeats) {
							<checkbox-group-input-form-field
								[formCtrl]="formCtrl()"
								[label]="internalLabel()"
								[options]="options()"
								[readonly]="readonly()" />
						} @else {
							<select-input-form-field
								short
								fullWidth
								[label]="internalLabel()"
								[formCtrl]="formCtrl()"
								[options]="options()"
								[readonly]="readonly()" />
						}
					} @else if (item().dataType == QuestionnaireItemType.Date) {
						<date-input-form-field
							short
							fullWidth
							[label]="internalLabel()"
							[formCtrl]="formCtrl()"
							[readonly]="readonly()" />
					} @else if (item().dataType == QuestionnaireItemType.Time) {
						<time-input-form-field
							short
							fullWidth
							[label]="internalLabel()"
							[formCtrl]="formCtrl()"
							[readonly]="readonly()" />
					} @else if (item().dataType == QuestionnaireItemType.DateTime) {
						<date-time-input-form-field
							short
							fullWidth
							[label]="internalLabel()"
							[formCtrl]="formCtrl()"
							[readonly]="readonly()" />
					} @else if (item().dataType == QuestionnaireItemType.Display) {
						@if (item().renderXhtml) {
							<!-- It's only XSS is we let the user control the questionnaire -->
							<div class="questionnaire-xhtml-container" [innerHTML]="item().renderXhtml"></div>
						}
					} @else {
						Not Yet Supported: {{ item().dataType }} - {{ item().text }}
					}
				</div>
			}
		</div>
	</div>
}
