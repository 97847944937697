@if (showFormField()) {
	<form [formGroup]="formGroup">
		<mat-form-field [ngClass]="{ 'full-width': fullWidth }">
			@if (label) {
				<mat-label>{{ label }}</mat-label>
			}

			<mtx-select
				[multiple]="multiple"
				[(ngModel)]="selectedItems"
				(change)="selectionChanged($event)"
				bindLabel="label"
				bindValue="key"
				[compareWith]="compareWith"
				[disabled]="disabled"
				[items]="items"
				[required]="true"
				[typeahead]="typeahead"
				[minTermLength]="minTypeaheadLength"
				[placeholder]="placeHolder ?? ''"
				[ngModelOptions]="{ standalone: true }"
				[loading]="loading">
				<ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
					<div>
						@if (item.isFavorite) {
							<mat-icon class="primary-color filled-star-hack">star_half</mat-icon>
						}
						{{ item.label }}
					</div>
				</ng-template>
			</mtx-select>

			@if (!hideErrorText) {
				<mat-error> A selection is required.</mat-error>
			}
		</mat-form-field>
	</form>
}
