@if (showFormField) {
	@if (readonly()) {
		<readonly-textarea-input
			[ngClass]="{ 'full-width': fullWidth }"
			[label]="label"
			[value]="formCtrl.value?.toString()" />
	} @else {
		<mat-form-field [ngClass]="{ 'full-width': fullWidth }">
			@if (label) {
				<mat-label>{{ label }}</mat-label>
			}

			@if (focusInitial) {
				<textarea
					matInput
					[formControl]="formCtrl"
					cdkTextareaAutosize
					[cdkAutosizeMinRows]="minRows()"
					[attr.maxLength]="maxLength()"
					cdkFocusInitial
					[readonly]="readonly()"></textarea>
			} @else {
				<textarea
					matInput
					[formControl]="formCtrl"
					cdkTextareaAutosize
					[cdkAutosizeMinRows]="minRows()"
					[attr.maxLength]="maxLength()"
					[readonly]="readonly()"></textarea>
			}

			@if (formCtrl && formCtrl.hasError('required')) {
				<mat-error>{{ errorLabelToLowerExceptFirst }} is required.</mat-error>
			}

			@if (formCtrl && formCtrl.hasError('minlength')) {
				<mat-error
					>{{ errorLabelToLowerExceptFirst }} must be more than
					{{ formCtrl.getError('minlength').requiredLength }}
					characters.</mat-error
				>
			}

			@if (formCtrl && formCtrl.hasError('maxlength')) {
				<mat-error
					>{{ errorLabelToLowerExceptFirst }} can only be up to
					{{ formCtrl.getError('maxlength').requiredLength }}
					characters.</mat-error
				>
			}

			<ng-template ngFor let-customError [ngForOf]="customErrors" ngProjectAs="mat-error">
				@if (formCtrl && formCtrl.hasError(customError.type)) {
					<mat-error>{{ customError.message }}</mat-error>
				}
			</ng-template>
		</mat-form-field>
	}
}
