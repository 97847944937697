/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum CustomInputControlTypeApiEnum {
    Temperature = 0,
    Slider = 1,
    BodyMeasurement = 2,
    BMI = 3,
    Height = 4,
    Weight = 5,
    FhirTypeahead = 6,
    VerticalRadio = 7,
    Dropdown = 8,
    Quantity = 9,
    PrimaryChiefComplaint = 10,
}

export const CustomInputControlTypeApiEnumDisplayMap : { [key in CustomInputControlTypeApiEnum] : string } = {
    [CustomInputControlTypeApiEnum.Temperature]: "Temperature",
    [CustomInputControlTypeApiEnum.Slider]: "Slider",
    [CustomInputControlTypeApiEnum.BodyMeasurement]: "BodyMeasurement",
    [CustomInputControlTypeApiEnum.BMI]: "BMI",
    [CustomInputControlTypeApiEnum.Height]: "Height",
    [CustomInputControlTypeApiEnum.Weight]: "Weight",
    [CustomInputControlTypeApiEnum.FhirTypeahead]: "FhirTypeahead",
    [CustomInputControlTypeApiEnum.VerticalRadio]: "VerticalRadio",
    [CustomInputControlTypeApiEnum.Dropdown]: "Dropdown",
    [CustomInputControlTypeApiEnum.Quantity]: "Quantity",
    [CustomInputControlTypeApiEnum.PrimaryChiefComplaint]: "PrimaryChiefComplaint",
}
