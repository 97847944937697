import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, forwardRef, Inject, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatSidenavModule } from '@angular/material/sidenav';

import { MtxLoaderModule } from '@ng-matero/extensions/loader';
import { take, tap } from 'rxjs';
import { AuthService } from '../../auth';
import { ChangeTestModeOptionsRequestApiModel, TestModeBaseDataApiModel } from '../../generated-models';
import { BaseIsProcessingComponent, SelectInputFormFieldComponent } from '../../shared';
import { AppSidenavService, DATA_INJECTION_TOKEN } from '../app-sidenav';

export type TestModeOptionsComponentData = object;

@Component({
	standalone: true,
	changeDetection: ChangeDetectionStrategy.OnPush,
	selector: 'test-mode-options-component',
	templateUrl: './test-mode-options.component.html',
	styleUrl: './test-mode-options.component.scss',
	imports: [
		CommonModule,
		MatIconModule,
		MatButtonModule,
		MatSidenavModule,
		ReactiveFormsModule,
		forwardRef(() => SelectInputFormFieldComponent),
		MtxLoaderModule
	]
})
export class TestModeOptionsComponent extends BaseIsProcessingComponent implements OnInit {
	@Input()
	public data: TestModeOptionsComponentData | null = null;

	public testModeBaseData: TestModeBaseDataApiModel | null = null;

	public readonly formGroup = new FormGroup({
		practitionerRoleId: new FormControl<string | null>(null, Validators.required),
		permissionClaimIds: new FormControl<string[] | null>([])
	});

	get practitionerRoleIdCtrl() {
		return this.formGroup.get('practitionerRoleId') as FormControl<string | null>;
	}

	get permissionClaimIdsCtrl() {
		return this.formGroup.get('permissionClaimIds') as FormControl<string[] | null>;
	}

	constructor(
		@Inject(DATA_INJECTION_TOKEN) _data: TestModeOptionsComponentData,
		public appSidenavService: AppSidenavService,
		private _authService: AuthService
	) {
		super([]);

		if (_data) {
			this.data = _data;
		}
	}

	public ngOnInit(): void {
		this._authService
			.getTestModeBaseData()
			.pipe(
				take(1),
				tap(response => {
					if (response.ok) {
						this.testModeBaseData = response.body ?? null;

						this.practitionerRoleIdCtrl.setValue(this.testModeBaseData?.currentPractitionerRoleId ?? null, {
							emitEvent: false
						});
						this.permissionClaimIdsCtrl.setValue(this.testModeBaseData?.currentPermissionClaimIds ?? [], {
							emitEvent: false
						});
					}
				})
			)
			.subscribe();
	}

	public save(): void {
		this.formGroup.markAllAsTouched();

		if (this.formGroup.valid) {
			const changeTestModeOptionsRequest: ChangeTestModeOptionsRequestApiModel = {
				practitionerRoleId: this.practitionerRoleIdCtrl.value!,
				permissionClaimIds: this.permissionClaimIdsCtrl.value ?? []
			};

			this._authService
				.changeTestModeOptions(changeTestModeOptionsRequest)
				.pipe(
					take(1),
					tap(response => {
						if (response.ok) {
							this.appSidenavService.closeSidenav();
							window.location.reload();
						}
					})
				)
				.subscribe();
		}
	}
}
