import { CommonModule } from '@angular/common';
import { Component, Inject, Input } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogActions, MatDialogContent, MatDialogRef } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { take, tap } from 'rxjs';
import { TimeLeftPipe } from '../../shared/pipes/time-left.pipe';
import { AuthService } from '../auth-service.service';

export interface SessionExpiringSoonDialogComponentData {
	sessionTimeLeftMS: number;
}

@Component({
	selector: 'session-expiring-soon-dialog',
	templateUrl: 'session-expiring-soon-dialog.component.html',
	standalone: true,
	imports: [
		MatFormFieldModule,
		MatInputModule,
		FormsModule,
		MatButtonModule,
		MatDialogContent,
		MatDialogActions,
		CommonModule,
		TimeLeftPipe
	]
})
export class SessionExpiringSoonDialogComponent {
	@Input()
	public data: SessionExpiringSoonDialogComponentData | null = null;

	constructor(
		@Inject(MAT_DIALOG_DATA)
		readonly _data: SessionExpiringSoonDialogComponentData,
		private readonly _dialogRef: MatDialogRef<SessionExpiringSoonDialogComponent>,
		private readonly _authService: AuthService
	) {
		if (_data) {
			this.data = _data;
		}
	}

	public logout(): void {
		this._authService.logout().subscribe(response => {
			if (response.ok) {
				this.close();
			}
		});
	}

	public refreshSession(): void {
		this._authService
			.refreshSession()
			.pipe(
				take(1),
				tap(response => {
					if (response.ok) {
						this.close();
					}
				})
			)
			.subscribe();
	}

	private close(): void {
		this._dialogRef.close();
	}

	public getRoundedSeconds(ms: number | undefined | null) {
		return Math.round((ms ?? 0) / 1000);
	}
}
