import { Injectable } from '@angular/core';

@Injectable()
export class SecurityService {
	private _claims: string[] = [];

	set claims(claims: string[] | null | undefined) {
		this._claims = claims ?? [];
	}

	public hasClaim(claimType: string | string[]) {
		// See if an array of values was passed in.
		if (typeof claimType === 'string') {
			return this._claims.includes(claimType);
		}

		const claims: string[] = claimType;
		return claims.some(claim => this._claims.includes(claim));
	}

	public hasClaims(claims: string[]) {
		return claims.every(claim => this._claims.includes(claim));
	}
}
