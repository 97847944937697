import { BooleanInput, coerceBooleanProperty } from '@angular/cdk/coercion';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, EventEmitter, Input, Output } from '@angular/core';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatToolbarModule } from '@angular/material/toolbar';
import { ActivatedRoute, NavigationEnd, Router, RouterLink, UrlSegment } from '@angular/router';
import { distinctUntilChanged, filter, map, take, takeUntil } from 'rxjs';
import { AuthService } from '../../auth';
import { BaseComponent, HasClaimDirective, SecurityService } from '../../shared';
import { DefaultPipe } from '../../shared/pipes/default.pipe';
import { FhirService } from '../../shared/services/fhir-service.service';
import { SiteWideDataService } from '../../shared/services/site-wide-data-service.service';
import { AppSidenavConfig, AppSidenavService } from '../app-sidenav';
import { ChangeFacilityComponent } from '../change-facility/change-facility.component';
import { TestModeOptionsComponent } from '../test-mode-options/test-mode-options.component';
import { Breadcrumb } from './models/breadcrumb.model';
@Component({
	standalone: true,
	changeDetection: ChangeDetectionStrategy.OnPush,
	selector: 'app-nav-bar',
	templateUrl: './app-nav-bar.component.html',
	styleUrl: './app-nav-bar.component.scss',
	imports: [
		CommonModule,
		MatToolbarModule,
		MatButtonModule,
		MatIconModule,
		MatMenuModule,
		MatBadgeModule,
		DefaultPipe,
		HasClaimDirective,
		RouterLink
	],
	providers: [SecurityService]
})
export class AppNavBarComponent extends BaseComponent {
	private _quickActionsOpened: boolean = false;

	@Output() quickActionsOpenedState: EventEmitter<boolean> = new EventEmitter(false);

	@Input()
	set quickActionsOpened(value: BooleanInput) {
		this._quickActionsOpened = coerceBooleanProperty(value);
		this.quickActionsOpenedState.emit(this._quickActionsOpened);
	}

	get quickActionsOpened(): boolean {
		return this._quickActionsOpened;
	}

	public breadcrumbs$ = this._router.events.pipe(
		takeUntil(this._destroy),
		filter(event => event instanceof NavigationEnd),
		distinctUntilChanged(),
		map(() => {
			return this.buildBreadCrumb(this._activatedRoute.root);
		})
	);

	public userFullName = computed<string | null>(() => {
		const userInfo = this.authService.userInfo();
		this._securityService.claims = userInfo?.claims ?? [];
		return `${userInfo?.title ? `${userInfo.title} ` : ''}${userInfo?.firstName} ${userInfo?.lastName}`;
	});

	public facilityName = computed<string | null>(() => {
		return this.authService.userInfo()?.facility?.label ?? null;
	});

	public selectedPatientEncounter = this.siteWideDataService.selectedEncounter;

	public visitNumberDisplay = computed<string | null>(() => {
		const encounter = this.selectedPatientEncounter();
		if (!encounter) {
			return null;
		}

		if (encounter.visitNumber) {
			return encounter.visitNumber.value ?? null;
		}

		const maxLen = 13;
		return encounter.id && encounter.id.length > maxLen
			? `${encounter.id.substring(0, maxLen)}...`
			: encounter.id || null;
	});

	constructor(
		private _router: Router,
		private _activatedRoute: ActivatedRoute,
		private _appSidenavService: AppSidenavService,
		private _securityService: SecurityService,
		public authService: AuthService,
		public fhirService: FhirService,
		public siteWideDataService: SiteWideDataService
	) {
		super();
	}

	public changeFacility() {
		const sidenavData = null;

		const appSidenavConfig: AppSidenavConfig = {
			title: 'Change Facility',
			onCloseCallback: () => {}
		};

		this._appSidenavService.showSidenav(ChangeFacilityComponent, sidenavData, appSidenavConfig);
	}

	public showTestModeOptions() {
		const sidenavData = null;

		const appSidenavConfig: AppSidenavConfig = {
			title: 'Test Mode Options',
			onCloseCallback: () => {}
		};

		this._appSidenavService.showSidenav(TestModeOptionsComponent, sidenavData, appSidenavConfig);
	}

	public logout(): void {
		this.authService.logout().pipe(take(1)).subscribe();
	}

	private buildBreadCrumb(routeRoot: ActivatedRoute): Breadcrumb[] {
		const newBreadcrumbs: Breadcrumb[] = [];
		let url: string = '';

		let currentRoute: ActivatedRoute | null = routeRoot;

		do {
			const childrenRoutes = currentRoute.children;
			currentRoute = null;
			childrenRoutes.forEach((route: ActivatedRoute) => {
				if (route.outlet === 'primary') {
					const routeSnapshot = route.snapshot;

					const label: string = route.routeConfig?.data ? route.routeConfig.data['breadcrumb'] : null;

					url += '/' + routeSnapshot.url.map((segment: UrlSegment) => segment.path).join('/');

					if (label && url) {
						newBreadcrumbs.push({
							label: label,
							url: url
						} as Breadcrumb);
					}

					currentRoute = route;
				}
			});
		} while (currentRoute);

		return newBreadcrumbs;
	}
}
